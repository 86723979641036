import React, { FunctionComponent } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

interface IUserMarker {
  userPosition?: any;
}

const UserMarker: FunctionComponent<IUserMarker> = ({ userPosition }) => {
  const icon = L.icon({
    iconUrl: "/images/my_position.svg",
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });

  return <Marker icon={icon} position={userPosition} />;
};

export default UserMarker;
