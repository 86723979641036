import React, {
  FunctionComponent,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import {
  RootState,
  DefaultApiResult,
  MapInterface,
} from "../../redux/reducers";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { IFloor, IRoom, IBuilding, IPoint, ISetting } from "../../interfaces";
import Box from "../../components/Box";
import Button from "../../components/FormElements/Button";
import Icon from "../../components/FormElements/Icons";
import Input from "../../components/FormElements/Input";
import { MapContext } from "../../contexts/MapContext";
import { renderPath, removePaths } from "./path";
import { onCopyUrl } from "../../services/copyUrl";
import { printMap } from "../../services/printMap";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import {
  HandicappedContext,
  IHandicappedContext,
} from "../../contexts/HandicappedContext";
import { checkIfContainsPoint } from "../../services/positions";
import Loader from "react-loader-spinner";
import { geocoder } from "../../services/geocoder";
import Checkbox from "../../components/FormElements/Checkbox";

const Paths: FunctionComponent = () => {
  const history = useHistory();
  const userLocation = useContext(GeolocationContext);
  const isResloved = userLocation?.status === "resolved";
  const {
    handicappedPath,
    setHandicappedPath,
  }: IHandicappedContext = useContext(HandicappedContext);

  const { mapInstance } = useContext(MapContext);
  const { destinationPoint, startingPoint } = useParams();

  const [locations, setLocations] = useState([]);
  const [startValue, setStartValue] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showTip, setShowTip] = useState(true);

  const destination = useMemo(() => {
    return {
      type: destinationPoint?.split("-")[0],
      id: Number(destinationPoint?.split("-")[1]),
    };
  }, [destinationPoint]);

  const start = useMemo(() => {
    if (startingPoint && isNaN(Number(startingPoint?.split("-")[0]))) {
      return {
        type: startingPoint?.split("-")[0],
        id: Number(startingPoint?.split("-")[1]),
      };
    } else {
      return {
        lat: Number(startingPoint?.split("-")[0]),
        lng: Number(startingPoint?.split("-")[1]),
      };
    }
  }, [startingPoint]);

  const settings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Settings
  );

  const points: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );

  const rooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const entrances: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Entrances
  );

  const mapData: MapInterface = useSelector<RootState, MapInterface>(
    (state) => state.Map
  );

  const allFloors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const routeColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "outside_path_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const getData = useCallback(
    (type: string, id: number) => {
      if (buildings?.data && points?.data && rooms?.data) {
        switch (type) {
          case "building":
            return {
              ...buildings?.data?.data?.find(
                (building: IBuilding) => building.id === id
              ),
              building_id: id,
            };
          case "room":
            return rooms?.data?.data?.find((room: IRoom) => room.id === id);
          case "point":
            return points?.data?.data?.find((point: IPoint) => point.id === id);
          case "floor":
            return allFloors?.data?.data?.find(
              (floor: IFloor) => floor.id === id
            );

          default:
            return;
        }
      }
    },
    [buildings, points, rooms]
  );

  const destinationData = useMemo(() => {
    const { type, id } = destination;
    if (type && id) {
      return getData(type, id);
    }
  }, [getData, destination]);

  const startData = useMemo(() => {
    const { type, id } = start;
    if (type && id) {
      return getData(type, id);
    }
  }, [getData, start]);

  const onSetFloorId = useCallback(
    (destFloorId: number, startFloorId: number) => {
      const startUrl = startingPoint
        ? startingPoint
        : start?.lat
        ? `${start.lat}-${start.lng}`
        : "";

      const path = `/paths/${destinationPoint}/${startUrl}?floor=${destFloorId}${
        startFloorId ? `/${startFloorId}` : ""
      }${handicappedPath ? "/handicapped" : ""}`;

      history.location.pathname !== path && history.push(path);
    },
    [destinationPoint, history, start, startingPoint, handicappedPath]
  );

  const destinationFloors = useMemo(
    () =>
      allFloors?.data?.data
        ?.filter(
          (floor: IFloor) => floor?.building_id === destinationData?.building_id
        )
        ?.sort((a: IFloor, b: IFloor) => a.level - b.level),
    [allFloors, destinationData]
  );

  const startFloors = useMemo(
    () =>
      startData?.building_id &&
      allFloors?.data?.data
        ?.filter(
          (floor: IFloor) => floor?.building_id === startData.building_id
        )
        ?.sort((a: IFloor, b: IFloor) => a.level - b.level),
    [allFloors, startData]
  );

  const onDownload = () => {
    printMap(mapInstance, true);
    setShowOptions(false);
  };

  const onPrint = () => {
    printMap(mapInstance, false);
    setShowOptions(false);
  };

  const onCopyClick = () => {
    onCopyUrl();
    setShowOptions(false);
  };

  const onSetStartPosition = (
    lat: number | string,
    lng: number | string,
    myLoc = false
  ) => {
    setShowResults(false);
    if (
      myLoc &&
      !checkIfContainsPoint(mapData.maxBounds, userLocation.position)
    ) {
      setShowPopup(true);
    } else {
      history.push(`/paths/${destinationPoint}/${lat}-${lng}`);
    }
  };

  useEffect(() => {
    if (destinationData?.id) {
      const no = history?.location?.search?.split("=")[1]?.split("/");
      const isPath = history?.location?.pathname?.split("/").includes("paths");

      isPath &&
        onSetFloorId(
          no?.length > 0 ? Number(no[0]) : destinationData?.floor_id,
          no?.length > 1 ? Number(no[1]) : startData?.floor_id
        );
    }
  }, [destinationData, onSetFloorId, history, startData]);

  useEffect(() => {
    if (
      destinationData &&
      !destinationData?.loading &&
      mapInstance &&
      destinationPoint &&
      entrances?.data?.data?.length > 0
    ) {
      renderPath(
        mapInstance,
        { ...destinationData, url: destinationPoint },
        startData || start,
        history,
        entrances,
        allFloors,
        routeColor
      );
    }
  }, [
    destinationData,
    destinationPoint,
    history,
    start,
    mapInstance,
    entrances,
    startData,
    allFloors,
    routeColor,
  ]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (startValue) {
        geocoder.geocode(startValue, (results: any) => {
          setLocations(
            results && results.length > 0 ? results : [{ name: "Brak wyników" }]
          );
        });
      }
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [startValue]);

  useEffect(() => {
    if (start && start.lat && start.lng && !showResults) {
      geocoder?.reverse({ lat: start.lat, lng: start.lng }, 1, (e: any) => {
        e.length > 0 && e[0]?.name && setStartValue(e[0].name);
      });
    }
  }, [start, showResults]);

  useEffect(() => {
    return () => {
      removePaths(mapInstance);
    };
  }, [mapInstance]);

  useEffect(() => {
    if (start && start.lat && start.lng && !showResults) {
      geocoder?.reverse({ lat: start.lat, lng: start.lng }, 1, (e: any) => {
        e.length > 0 && e[0]?.name && setStartValue(e[0].name);
      });
    }
  }, [start, showResults]);

  return (
    <section className="container element paths__wrapper">
      <div className="scrollable point">
        {showPopup && (
          <div className="popup row">
            Aktualnie nie znajdujesz się w obrębie Politechniki
            <Button
              className="button-transparent"
              onClick={() => setShowPopup(false)}
            >
              <Icon name="close_background" />
            </Button>
          </div>
        )}

        {!startingPoint && showTip && (
          <div className="popup">
            Wyszukaj lokalizację w wyszukiwarce lub kliknij na mapę.
            <Button
              className="button-transparent"
              onClick={() => setShowTip(false)}
            >
              <Icon name="close_background" />
            </Button>
          </div>
        )}

        <Box loading={destinationData?.loading} title="Trasa">
          <>
            <div className="row">
              <div className="col-24">
                <div className="point__content">
                  <Checkbox
                    id={"onlyInCampus"}
                    isChecked={handicappedPath ? handicappedPath : false}
                    onCheck={() => setHandicappedPath(!handicappedPath)}
                    label="Trasa preferowana dla osób niepełnosprawnych ruchowo"
                  />
                  <div className="point__buttons">
                    <Button
                      onClick={(e) => setShowOptions(!showOptions)}
                      className="button-white"
                    >
                      <>
                        <Icon name="options" /> Opcje
                      </>
                    </Button>

                    {showOptions && (
                      <div
                        onClick={() => setShowOptions(false)}
                        className="point__options-wrapper"
                      >
                        <Button
                          onClick={() => onCopyClick()}
                          className="button-white"
                        >
                          <>
                            <Icon name="copy_url" /> Kopiuj adres URL ścieżki
                          </>
                        </Button>
                        <Button
                          className="button-white"
                          onClick={() => onDownload()}
                        >
                          <>
                            <Icon name="download" /> Pobierz
                          </>
                        </Button>
                        <Button
                          className="button-white"
                          onClick={() => onPrint()}
                        >
                          <>
                            <Icon name="print" /> Wydrukuj
                          </>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-24">
                <div className="paths">
                  <div className="paths__points">
                    <div className="paths__element--red">
                      {startData ? (
                        <span className="font-700">{startData?.name}</span>
                      ) : (
                        <Input
                          className="transparnet with-icon"
                          type="text"
                          changeValue={(e: string) => setStartValue(e)}
                          icon={
                            showResults || startValue
                              ? "close_background"
                              : "search"
                          }
                          placeholder={
                            startValue
                              ? "Wyszukaj lokalizację lub przesuń marker"
                              : "Wyszukaj lokalizację lub kliknij na mapę..."
                          }
                          value={startValue}
                          isFocued={(e) => setShowResults(e)}
                          url={`/paths/${destinationPoint}`}
                        />
                      )}
                    </div>
                    {showResults && (
                      <div className="search__result">
                        <ul>
                          {isResloved && (
                            <li
                              onClick={() =>
                                userLocation &&
                                userLocation?.position &&
                                onSetStartPosition(
                                  userLocation?.position[0],
                                  userLocation.position[1],
                                  true
                                )
                              }
                              className="search__result__point"
                            >
                              <Icon name="position" /> Twoja lokalizacja
                            </li>
                          )}
                          {loading && (
                            <li className="search__result__loader">
                              <div className="loader">
                                <Loader
                                  type="TailSpin"
                                  color="#00BFFF"
                                  height={50}
                                  width={50}
                                />
                              </div>
                            </li>
                          )}
                          {locations &&
                            locations?.length > 0 &&
                            !loading &&
                            locations.map((location: any, row: number) => {
                              return (
                                <li
                                  onClick={() =>
                                    location?.center?.lat &&
                                    onSetStartPosition(
                                      location.center.lat,
                                      location.center.lng
                                    )
                                  }
                                  key={row}
                                >
                                  {location.name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                    {destinationData?.name && (
                      <span className="paths__element paths__element--blue font-700">
                        {destinationData.name}
                      </span>
                    )}

                    {startData?.building_id !== destinationData?.building_id &&
                      startFloors?.length > 0 && (
                        <div className="point__floors">
                          <span className="paths__path-heading font-700">
                            Mapy budynku:
                            <span>
                              {
                                buildings?.data?.data?.find(
                                  (building: IBuilding) =>
                                    building.id === startData?.building_id
                                )?.name
                              }
                            </span>
                          </span>
                          <ul className="point__list row">
                            {startFloors?.map((floor: IFloor) => {
                              return (
                                <li
                                  className="col-md-6 col-sm-12 col-12"
                                  key={floor.id}
                                >
                                  <Button
                                    onClick={() =>
                                      onSetFloorId(
                                        Number(
                                          history?.location?.search
                                            ?.split("=")[1]
                                            ?.split("/")[0]
                                        ),
                                        floor.id
                                      )
                                    }
                                    className={[
                                      startData?.floor_id === floor.id
                                        ? "button-blue"
                                        : "button-white",
                                      Number(
                                        history?.location?.search
                                          ?.split("=")[1]
                                          ?.split("/")[1]
                                      ) === floor.id && "selected",
                                    ].join(" ")}
                                  >
                                    <span>Piętro {floor.level}</span>
                                  </Button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                    {destinationFloors?.length > 0 && (
                      <div className="point__floors">
                        <span className="paths__path-heading font-700">
                          Mapy budynku:
                          <span>
                            {
                              buildings?.data?.data?.find(
                                (building: IBuilding) =>
                                  building.id === destinationData?.building_id
                              )?.name
                            }
                          </span>
                        </span>

                        <ul className="point__list row">
                          {destinationFloors?.map((floor: IFloor) => {
                            return (
                              <li
                                className="col-md-6 col-sm-12 col-12"
                                key={floor.id}
                              >
                                <Button
                                  onClick={() =>
                                    onSetFloorId(
                                      floor.id,
                                      Number(
                                        history?.location?.search
                                          ?.split("=")[1]
                                          ?.split("/")[1]
                                      )
                                    )
                                  }
                                  className={[
                                    destinationData?.floor_id === floor.id
                                      ? "button-blue"
                                      : "button-white",
                                    Number(
                                      history?.location?.search
                                        ?.split("=")[1]
                                        ?.split("/")[0]
                                    ) === floor.id && "selected",
                                  ].join(" ")}
                                >
                                  <span>Piętro {floor.level}</span>
                                </Button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    <span className="paths__path-heading font-700">
                      Wskazówki trasy:
                    </span>
                    <div id="paths__tips" />
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </div>
    </section>
  );
};

export default Paths;
