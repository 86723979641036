import React, {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import { Map, TileLayer } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setMapPosition } from "../../../redux/map/actions";
import {
  RootState,
  MapInterface,
  DefaultApiResult,
} from "../../../redux/reducers";
import { useLocation } from "react-router-dom";
import UserMarker from "../UserMarker";
import GeojsonWrapper from "./../GeojsonWrapper/index";
import MarkersList from "./../MarkersList";
import EntrancesMarkers from "./../EntrancesMarkers";
import {
  IPoint,
  IRoute,
  IRoom,
  IBuilding,
  IEntrance,
  ISetting,
} from "../../../interfaces";
import { GeolocationContext } from "../../../contexts/GeolocationContext";
import { MapContext } from "../../../contexts/MapContext";
import { EventContext } from "../../../contexts/EventContext";
import { getBounds, convertXY } from "../../../services/positions";
import Routes from "../Routes";
import MapButtons from "../MapButtons";
import Loader from "react-loader-spinner";

const MapWrapper: FunctionComponent = () => {
  const location = useLocation();
  const dispatch: Dispatch = useDispatch();
  const mapRef: any = useRef(null);
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";
  const { setMapInstance } = useContext(MapContext);
  const { selectedEvent } = useContext(EventContext);
  const [floorId, setFloorId] = useState("");
  const [buildingId, setBuildingId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [pointId, setPointId] = useState("");
  // CHECK IF SHOULD SHOW PATHS
  const isPath = useMemo(() => location.pathname.split("/").includes("paths"), [
    location,
  ]);

  const handicapped = useMemo(() => {
    return location.search.split("/")?.includes("handicapped");
  }, [location]);

  const start = useMemo(() => {
    const startUrl = location.pathname?.split("/")[3]?.split("-");

    if (startUrl?.length > 0) {
      if (startUrl?.length > 3) {
        return {
          type: "floor",
          id: Number(startUrl[1]),
          lat: Number(startUrl[2]),
          lng: Number(startUrl[3]),
        };
      }
      if (isNaN(Number(startUrl[0]))) {
        return {
          type: startUrl[0],
          id: Number(startUrl[1]),
        };
      } else if (startUrl?.length > 1) {
        return {
          lat: Number(startUrl[0]),
          lng: Number(startUrl[1]),
        };
      }
    }
    return null;
  }, [location.pathname]);

  const destination = useMemo(() => {
    let path = location.pathname;

    if (handicapped) {
      path = location.pathname.split("&")[0];
    }
    let url = path.split("/");

    return {
      type: isPath ? url[2].split("-")[0] : url[1],
      id: isPath ? url[2].split("-")[1] : url[2],
    };
  }, [location, isPath, handicapped]);

  const mapData: MapInterface = useSelector<RootState, MapInterface>(
    (state) => state.Map
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const rooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const allPoints: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );

  const routes: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Routes
  );

  const settings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Settings
  );

  // STYLES AND ICONS FROM CMS

  const selectedColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "selected_element_color"
      )?.value || "#ffaf00",

    [settings]
  );

  const routeColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "inside_path_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const startPointColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "start_point_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const endPointColor = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "end_point_color"
      )?.value || "#ffaf00",
    [settings]
  );

  const startPointImage = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "start_point_image"
      )?.value || null,
    [settings]
  );

  const endPointImage = useMemo(
    () =>
      settings?.data?.data?.find(
        (setting: ISetting) => setting?.key === "end_point_image"
      )?.value || null,
    [settings]
  );

  const allEntrances: DefaultApiResult = useSelector<
    RootState,
    DefaultApiResult
  >((state) => state.Entrances);

  const loading =
    mapData?.loading ||
    // allPoints?.loading ||
    routes?.loading ||
    allEntrances?.loading ||
    settings?.loading;

  const building = useMemo(
    () =>
      buildingId &&
      (buildings?.byId[buildingId]?.data ||
        buildings?.data?.data?.find(
          (building: IBuilding) => building.id === Number(buildingId)
        )),
    [buildingId, buildings]
  );

  const destinationRoom = useMemo(
    () =>
      roomId &&
      (rooms?.byId[roomId]?.data ||
        rooms?.data?.data?.find((room: IRoom) => room.id === Number(roomId))),
    [roomId, rooms]
  );

  const destinationPoint = useMemo(
    () =>
      pointId &&
      (allPoints?.byId[pointId]?.data ||
        allPoints?.data?.data?.find(
          (point: IPoint) => point.id === Number(pointId)
        )),
    [pointId, allPoints]
  );

  const startRoom = useMemo(() => {
    if (start?.id && start?.type === "room") {
      return (
        rooms?.byId[start?.id]?.data ||
        rooms?.data?.data?.find((room: IRoom) => room.id === start?.id)
      );
    }
  }, [start, rooms]);

  const startPoint = useMemo(() => {
    if (start?.id && start?.type === "point") {
      return (
        allPoints?.byId[start?.id]?.data ||
        allPoints?.data?.data?.find((point: IPoint) => point.id === start?.id)
      );
    }
  }, [start, allPoints]);

  const points = useMemo(
    () => (allPoints?.data?.data?.length > 0 && allPoints?.data?.data) || [],
    [allPoints]
  );

  // FILTER ENTRANCES IF HANDICAPPED
  const entrances = useMemo(() => {
    if (allEntrances?.data?.data?.length > 0) {
      if (handicapped) {
        return allEntrances?.data?.data.filter((entrance: IEntrance) => {
          if (entrance.type === "stairs" || entrance.type === "elevator") {
            return entrance.handicap_enabled;
          } else {
            return entrance;
          }
        });
      } else {
        return allEntrances?.data?.data;
      }
    } else {
      return [];
    }
  }, [allEntrances, handicapped]);

  const destinationFloorId = useMemo(() => {
    if (location?.search?.length > 0) {
      return location.search.split("=")[1]?.split("/")[0] || "";
    }
    return false;
  }, [location]);

  const startFloorId = useMemo(() => {
    if (location?.search?.length > 0) {
      return location.search.split("=")[1]?.split("/")[1] || "";
    }
    return false;
  }, [location]);

  const startData = useMemo(() => {
    if (start?.type === "floor" && start?.lat) {
      return {
        ...start,
        building_id: Number(buildingId),
        coordinates: convertXY(start.lng, start.lat),
        floor_id: start.id,
      };
    }
    if (start && (startRoom || startPoint)) {
      if (start?.type === "point" && startPoint?.coordinates_y) {
        return {
          ...startPoint,
          type: start?.type,
          coordinates: convertXY(
            startPoint.coordinates_y,
            startPoint.coordinates_x
          ),
        };
      } else if (startRoom?.coordinates) {
        return { ...startRoom, type: start?.type };
      }
    } else {
      return start || null;
    }
  }, [start, startPoint, startRoom, buildingId]);

  const destinationData = useMemo(() => {
    if ((destinationPoint || destinationRoom) && destination?.type) {
      if (destination.type === "point" && destinationPoint?.coordinates_y) {
        return {
          ...destinationPoint,
          type: destination.type,
          coordinates: convertXY(
            destinationPoint.coordinates_y,
            destinationPoint.coordinates_x
          ),
        };
      } else if (destinationRoom?.coordinates && destination.type === "room") {
        return { ...destinationRoom, type: destination.type };
      }
    }
    return destination || null;
  }, [destinationPoint, destinationRoom, destination]);

  const sameBuilding = useMemo(() => {
    if (startData?.building_id && destinationData?.building_id) {
      return startData.building_id === destinationData.building_id;
    }
    return false;
  }, [startData, destinationData]);

  const alonePoints = useMemo(
    () =>
      points?.length > 0
        ? points.filter(
            (point: IPoint) => !point.building_id && !point.floor_id
          )
        : [],
    [points]
  );

  const destinationPoints = useMemo(
    () =>
      points?.length > 0 && buildingId && floorId
        ? points.filter(
            (point: IPoint) =>
              point.building_id === Number(buildingId) &&
              point.floor_id === Number(floorId)
          )
        : [],
    [buildingId, floorId, points]
  );

  const startPoints = useMemo(
    () =>
      points?.length > 0 && buildingId && floorId
        ? points.filter(
            (point: IPoint) =>
              point.building_id === startData?.building_id &&
              point.floor_id === (Number(startFloorId) || startData?.floor_id)
          )
        : [],
    [buildingId, floorId, points, startData, startFloorId]
  );

  const destinationEntrances = useMemo(() => {
    if (entrances?.length > 0 && floorId && buildingId) {
      return entrances.filter((point: IPoint) => {
        if (point.type === "outside") {
          return point.building_id === Number(buildingId);
        } else {
          return (
            point.building_id === Number(buildingId) &&
            point.floor_id === Number(floorId)
          );
        }
      });
    }
  }, [entrances, buildingId, floorId]);

  const startEntrances = useMemo(() => {
    if (entrances?.length > 0 && startFloorId && !sameBuilding)
      return entrances.filter((point: IPoint) => {
        if (point.type === "outside") {
          return (
            point.building_id === (startData?.building_id || Number(start?.id))
          );
        } else {
          return (
            point.building_id ===
              (startData?.building_id || Number(start?.id)) &&
            point.floor_id === Number(startFloorId)
          );
        }
      });
  }, [entrances, startData, startFloorId, start, sameBuilding]);

  const destinationRoute = useMemo(() => {
    if (buildingId && floorId && routes?.data?.data?.length > 0)
      return routes.data.data.find(
        (route: IRoute) =>
          route?.building_id === Number(buildingId) &&
          route?.floor_id === Number(floorId)
      );
  }, [buildingId, floorId, routes]);

  const startRoute = useMemo(() => {
    if (startFloorId && routes?.data?.data?.length > 0) {
      return routes.data.data.find(
        (route: IRoute) =>
          route?.building_id === Number(startData?.building_id) &&
          route?.floor_id === Number(startFloorId)
      );
    }
  }, [startData, startFloorId, routes]);

  const showDetails = useMemo(
    () => mapData?.position?.zoom && mapData.position.zoom > 18,
    [mapData]
  );

  // SET DATA FOR ELEMENTS FROM URL
  useEffect(() => {
    if (destination?.type && destination?.id) {
      if (destination.type === "building") {
        setBuildingId(destination.id || "");
        setFloorId("");
        setRoomId("");
        setPointId("");
      } else if (destination.type === "room") {
        setRoomId(destination.id || "");
        setPointId("");
      } else if (destination.type === "point") {
        setPointId(destination.id || "");
        setRoomId("");
      } else {
        setFloorId("");
        setRoomId("");
        setBuildingId("");
      }
    } else {
      setBuildingId("");
      setFloorId("");
      setRoomId("");
    }
  }, [destination, destinationFloorId]);

  useEffect(() => {
    if (destinationFloorId && floorId !== destinationFloorId) {
      setFloorId(destinationFloorId);
    }
  }, [destinationFloorId, floorId]);

  useEffect(() => {
    if (
      buildingId !== String(destinationData?.building_id) &&
      destinationData?.building_id
    ) {
      setBuildingId(String(destinationData.building_id));
    }
  }, [destinationData, buildingId]);

  // POSITION MAP ON BUILDING SELECT
  useEffect(() => {
    if (building) {
      if (building?.coordinates?.coordinates) {
        const bounds = getBounds(building?.coordinates.coordinates?.flat(2));
        const correctBounds = [
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()],
        ];
        // mapRef.current.leafletElement.setMaxBounds(correctBounds);
        mapRef.current.leafletElement.fitBounds(correctBounds);
      }
    } else {
      mapRef.current.leafletElement.setMaxBounds(mapData.maxBounds);
    }
  }, [buildings, building, mapData.maxBounds]);

  // SET MAP CONTEXT FOR OTHER VIEWS
  useEffect(() => {
    if (mapRef?.current?.leafletElement) {
      setMapInstance(mapRef.current.leafletElement);
    }
  }, [mapRef, setMapInstance]);

  return (
    <div className="map-wrapper">
      <MapButtons />
      {mapData && (
        <Map
          tap={false}
          minZoom={11}
          maxZoom={23}
          maxNativeZoom={19}
          center={mapData?.position?.center}
          zoom={mapData?.position?.zoom}
          ref={mapRef}
          useFly={true}
          zoomControl={false}
          routeWhileDragging={true}
          onZoomEnd={(e: any) =>
            dispatch(
              setMapPosition({
                zoom: e.target.getZoom(),
                center: e.target.getCenter(),
              })
            )
          }
        >
          {!isPath && buildingId && showDetails ? (
            <></>
          ) : (
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
          )}
          {isResolved && <UserMarker userPosition={userLocation?.position} />}
          <GeojsonWrapper
            buildingId={buildingId}
            floorId={Number(floorId)}
            roomId={roomId}
            pointId={pointId}
            showDetails={showDetails}
            loading={loading}
            start={!sameBuilding ? startData : start}
            startFloor={
              !sameBuilding ? Number(startFloorId) || startData?.floor_id : null
            }
            startColor={startPointColor}
            endColor={endPointColor}
            startIcon={startPointImage}
            endIcon={endPointImage}
            selectedColor={selectedColor}
            isPath={isPath}
          />
          {!loading ? (
            <>
              {!showDetails && selectedEvent && selectedEvent?.points && (
                <MarkersList data={selectedEvent?.points} />
              )}

              {alonePoints?.length > 0 && !selectedEvent && (
                <MarkersList data={alonePoints} />
              )}

              {showDetails && (
                <>
                  {destinationPoints && destinationPoints?.length > 0 && (
                    <MarkersList
                      data={destinationPoints}
                      selected={destinationPoint}
                      selectedColor={selectedColor}
                    />
                  )}
                  {startPoints && startPoints?.length > 0 && (
                    <MarkersList
                      data={startPoints}
                      selected={startPoint}
                      selectedColor={selectedColor}
                    />
                  )}
                  {destinationEntrances && destinationEntrances?.length > 0 && (
                    <EntrancesMarkers
                      data={destinationEntrances?.filter(
                        (point: IPoint) => point.floor_id === Number(floorId)
                      )}
                      selectedColor={selectedColor}
                    />
                  )}
                  {startEntrances && startEntrances?.length > 0 && (
                    <EntrancesMarkers
                      data={startEntrances?.filter(
                        (point: IPoint) =>
                          point.floor_id === Number(startFloorId)
                      )}
                      selectedColor={selectedColor}
                    />
                  )}
                  {mapRef?.current?.leafletElement &&
                    isPath &&
                    destinationData &&
                    startData && (
                      <>
                        {destinationRoute && destinationEntrances && (
                          <Routes
                            map={mapRef.current.leafletElement}
                            route={destinationRoute}
                            entrances={destinationEntrances}
                            destination={destinationData}
                            start={startData}
                            sameBuilding={sameBuilding}
                            type="destination"
                            color={routeColor}
                          />
                        )}
                        {startRoute && startEntrances && !sameBuilding && (
                          <Routes
                            map={mapRef.current.leafletElement}
                            route={startRoute}
                            entrances={startEntrances}
                            destination={startData}
                            start={destinationData}
                            sameBuilding={sameBuilding}
                            type="start"
                            color={routeColor}
                          />
                        )}
                      </>
                    )}
                </>
              )}
            </>
          ) : (
            <div className="loader loader--map__loader">
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          )}
        </Map>
      )}
    </div>
  );
};

export default MapWrapper;
