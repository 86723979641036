import L from "leaflet";
import "leaflet-easyprint";

export const printMap = (map: any, download: boolean) => {
  if (map) {
    const printPlugin = (L as any)
      .easyPrint({
        position: "bottomleft",
        sizeModes: ["A4Portrait", "A4Landscape"],
        filename: "wskazówki-trasy",
        customWindowTitle: "Wskazówki trasy",
        hideControlContainer: false,
        spinnerBgColor: "#000000",
        exportOnly: download,
        hidden: true,
      })
      .addTo(map);
    printPlugin.printMap("A4Landscape page", "wskazówki-trasy");
  }
};
