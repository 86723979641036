import React, {
  FunctionComponent,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { GeoJSON, Polyline, Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import {
  IEntrance,
  IFloor,
  ILatLng,
  IRoute,
  IPoint,
} from "../../../interfaces";
import { RootState, DefaultApiResult } from "../../../redux/reducers";
import {
  getCenter,
  findClosest,
  checkIfContainsPoint,
  findClosestLayer,
  findClosestMarker,
} from "../../../services/positions";
import { LatLng } from "leaflet";
import { StairsContext, IStairsContext } from "../../../contexts/StairsContext";
interface IRoutes {
  map: L.Map;
  route?: IRoute;
  entrances: any;
  destination: IPoint;
  start?: any;
  sameBuilding: boolean;
  type: string;
  color: string;
}

const Routes: FunctionComponent<IRoutes> = ({
  map,
  route,
  entrances,
  destination,
  start,
  sameBuilding,
  type,
  color,
}) => {
  const floors: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Floors
  );

  const { stairsPoints, setStairsPoints }: IStairsContext = useContext(
    StairsContext
  );

  const convertedEntrances = useMemo(() => {
    if (entrances && map && route?.coordinates?.coordinates) {
      return entrances?.map((entrance: IEntrance) => {
        return {
          ...entrance,
          closest: findClosestLayer(
            map,
            route.coordinates.coordinates,
            getCenter(entrance.coordinates.coordinates.flat(2))
          ),
        };
      });
    }
  }, [entrances, map, route]);

  const getFloor = useCallback(
    (data) => {
      if (floors && data) {
        return floors?.data?.data?.find(
          (floor: IFloor) => floor?.id === data?.floor_id
        );
      }
    },
    [floors]
  );

  const checkIfHallway = useCallback((entrances) => {
    return entrances.find(
      (entrance: IEntrance) => entrance.type === "inside_hallway"
    );
  }, []);

  const destinationFloor = useMemo(() => getFloor(destination), [
    destination,
    getFloor,
  ]);

  const startFloor = useMemo(() => start && getFloor(start), [start, getFloor]);

  // CURRENT SELECTED FLOOR
  const currentFloor = useMemo(() => getFloor(route), [route, getFloor]);

  // ALL EXITS IN THE BUILDING
  const exits = useMemo(
    () =>
      convertedEntrances?.filter(
        (entrance: IEntrance) => entrance.type === "outside"
      ),
    [convertedEntrances]
  );

  // GET CLOSEST EXIT
  const closestExit = useMemo(() => {
    if (map && exits?.length > 0 && (start?.lat || start?.coordinates)) {
      let currentExits;

      const closestFloor = exits
        .map((exit: IEntrance) => getFloor(exit).level)
        .reduce((a: number, b: number) => {
          return Math.abs(b - getFloor(destination)?.level) <
            Math.abs(a - getFloor(destination)?.level)
            ? b
            : a;
        });

      if (
        destination?.floor_id &&
        exits.find((exit: IEntrance) => exit.floor_id === destination?.floor_id)
      ) {
        currentExits = exits.filter(
          (exit: IEntrance) => exit.floor_id === destination?.floor_id
        );
      } else {
        currentExits = exits?.filter(
          (exit: IEntrance) => getFloor(exit).level === closestFloor
        );
      }

      const indicator = start.lat
        ? { lat: start.lng, lng: start.lat }
        : getCenter(start?.coordinates?.coordinates);

      if (currentExits) {
        return findClosestMarker(map, currentExits, indicator);
      }
    }
  }, [exits, map, start, destination, getFloor]);

  const exitsOnCurrentFloor = useMemo(
    () =>
      getFloor(closestExit)?.level === currentFloor?.level ? [closestExit] : [],
    [closestExit, currentFloor, getFloor]
  );

  // CHECK IF EXIT IS UPSTAIRS OR DOWNSTAIRS
  const exitUpstairs = useMemo(() => {
    if (currentFloor && closestExit) {
      return getFloor(closestExit)?.level >= currentFloor?.level;
    }
  }, [closestExit, currentFloor, getFloor]);

  // ALL STAIRS AND ELEVATORS IN CURRENT BUILDING
  const stairs = useMemo(
    () =>
      convertedEntrances?.filter(
        (entrance: IEntrance) =>
          getFloor(entrance)?.level === currentFloor?.level &&
          (entrance.type === "stairs" || entrance.type === "elevator") &&
          entrance.building_id === destination?.building_id
      ),
    [convertedEntrances, getFloor, currentFloor, destination]
  );

  // CHECK IF DESTINATION IS ON THE SAME FLOOR AS CURRENT SELECTED FLOOR
  const destinationOnCurrentFloor = useMemo(
    () => destinationFloor?.id === currentFloor?.id,
    [currentFloor, destinationFloor]
  );

  // STARTING ROOM ENTRANCES OR START POINT
  const startEntrances = useMemo(() => {
    if (start && convertedEntrances) {
      const currentRoomEntrances =
        start?.type === "room"
          ? convertedEntrances?.filter(
              (entrance: IEntrance) => entrance?.room_id === start?.id
            )
          : [start];

      if (
        checkIfHallway(currentRoomEntrances) &&
        currentRoomEntrances?.length > 0
      ) {
        return currentRoomEntrances.filter(
          (entrance: IEntrance) => entrance.type === "inside_hallway"
        );
      }
      return currentRoomEntrances;
    }
  }, [start, convertedEntrances, checkIfHallway]);

  // DESTINATION ROOM ENTRANCES OR DESTINATION POINT
  const destinationEntrances = useMemo(() => {
    if (destination && convertedEntrances) {
      const currentRoomEntrances =
        destination?.type === "room"
          ? convertedEntrances?.filter(
              (entrance: IEntrance) => entrance?.room_id === destination?.id
            )
          : [destination];

      if (
        checkIfHallway(currentRoomEntrances) &&
        currentRoomEntrances?.length > 0
      ) {
        return currentRoomEntrances.filter(
          (entrance: IEntrance) => entrance.type === "inside_hallway"
        );
      }
      return currentRoomEntrances;
    }
  }, [destination, convertedEntrances, checkIfHallway]);

  // CURRENT START POINT ENTRANCES/STAIRS/EXITS
  const startPoints = useMemo(() => {
    if (startEntrances && startFloor?.id === currentFloor?.id) {
      console.log("1");
      return startEntrances;
    } else if (
      exitsOnCurrentFloor?.length > 0 &&
      (!start?.type || !sameBuilding)
    ) {
      console.log("2");

      return exitsOnCurrentFloor;
    } else {
      if (destinationOnCurrentFloor) {
        console.log("3");

        return stairs.filter(
          (s: IEntrance) =>
            (exitUpstairs ? s.direction === "up" : s.direction === "down") ||
            !s.direction
        );
      } else {
        if (sameBuilding) {
          return stairs.filter(
            (s: IEntrance) =>
              (destinationFloor?.level > currentFloor?.level
                ? s.direction === "down"
                : s.direction === "up") || !s.direction
          );
        } else {
          return stairs.filter(
            (s: IEntrance) =>
              (startFloor?.level > currentFloor?.level
                ? s.direction === "up"
                : s.direction === "down") || !s.direction
          );
        }
      }
    }
  }, [
    exitUpstairs,
    exitsOnCurrentFloor,
    stairs,
    destinationOnCurrentFloor,
    destinationFloor,
    currentFloor,
    startEntrances,
    startFloor,
    start,
    sameBuilding,
  ]);

  // DESTINATION POINTS ENTRANCES/STAIRS
  const destinationPoints = useMemo(() => {
    if (destinationOnCurrentFloor && destinationEntrances) {
      return destinationEntrances;
    } else {
      return stairs.filter(
        (s: IEntrance) =>
          (destinationFloor.level > currentFloor.level
            ? s.direction === "up"
            : s.direction === "down") || !s.direction
      );
    }
  }, [
    destinationOnCurrentFloor,
    stairs,
    currentFloor,
    destinationEntrances,
    destinationFloor,
  ]);

  // console.log(startPoints);
  // console.log(destinationPoints);

  // CLOSEST TO START START POINT
  const startPoint = useMemo(() => {
    if (exitsOnCurrentFloor?.length > 0 && start?.lat) {
      return findClosest(
        map,
        startPoints.map((point: IEntrance) =>
          getCenter(point.coordinates.coordinates.flat(2))
        ),
        { lat: start?.lng, lng: start?.lat }
      );
    } else if (
      startPoints?.length > 0 &&
      destination?.coordinates?.coordinates
    ) {
      if (sameBuilding) {
        return findClosest(
          map,
          startPoints.map((point: IEntrance) =>
            getCenter(point.coordinates.coordinates.flat(2))
          ),
          start?.lat
            ? { lat: start?.lng, lng: start?.lat }
            : getCenter(start.coordinates.coordinates.flat(2))
        );
      } else {
        return findClosest(
          map,
          startPoints.map((point: IEntrance) =>
            getCenter(point.coordinates.coordinates.flat(2))
          ),
          getCenter(destination.coordinates.coordinates.flat(2))
        );
      }
    }
  }, [startPoints, destination, map, start, exitsOnCurrentFloor, sameBuilding]);

  // CLOSEST TO CLOSEST START POINT PATH COORDS
  const pathStartPoint: LatLng = useMemo(() => {
    if (map && route?.coordinates?.coordinates && startPoint) {
      return findClosest(
        map,
        route.coordinates.coordinates.flat(1),
        startPoint
      );
    }
  }, [map, route, startPoint]);

  // CLOSEST TO DESTINATION DESTINATION POINT
  const destinationPoint = useMemo(() => {
    if (
      destinationPoints?.length > 0 &&
      destination?.coordinates?.coordinates
    ) {
      return findClosest(
        map,
        destinationPoints.map((point: IPoint) =>
          getCenter(point?.coordinates?.coordinates.flat(2))
        ),
        destinationOnCurrentFloor
          ? getCenter(destination.coordinates.coordinates.flat(2))
          : startPoint?.lat
          ? { lat: startPoint.lat, lng: startPoint.lng }
          : { lat: start.lng, lng: start.lat }
      );
    }
  }, [
    destinationPoints,
    map,
    destination,
    startPoint,
    destinationOnCurrentFloor,
    start,
  ]);

  // CLOSEST TO CLOSEST DESTINATION POINT PATH COORDS
  const pathDestinationPoint: ILatLng = useMemo(() => {
    if (
      map &&
      route?.coordinates?.coordinates &&
      destinationPoint &&
      destination?.coordinates?.coordinates?.flat(1)?.length > 0
    ) {
      return findClosest(
        map,
        route.coordinates.coordinates
          .flat(1)
          .filter(
            (point: IPoint) =>
              !checkIfContainsPoint(
                destination.coordinates.coordinates.flat(1),
                point
              )
          ),
        destinationPoint
      );
    }
  }, [map, route, destinationPoint, destination]);

  // CHECK IF CLOSEST DESTINATION POINT IS SAME AS CLOSEST START POINT
  const isSamePoint = useMemo(
    () =>
      startPoint?.lat === destinationPoint?.lat &&
      startPoint?.lng === destinationPoint?.lng,
    [startPoint, destinationPoint]
  );

  const PathFinder = require("./../geojson-path-finder/index");
  const point = require("@turf/helpers").point;

  const geojson = useMemo(() => {
    if (route?.coordinates?.coordinates?.length > 0) {
      return {
        type: "FeatureCollection",
        features: route?.coordinates.coordinates.map((coordinates: LatLng) => {
          return {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: coordinates,
            },
          };
        }),
      };
    }
  }, [route]);

  const pathfinder = useMemo(() => {
    if (geojson) {
      return new PathFinder(geojson, {
        precision: 1e-20,
      });
    }
  }, [geojson, PathFinder]);

  const begining = useMemo(
    () =>
      pathStartPoint?.lat && point([pathStartPoint.lat, pathStartPoint.lng]),
    [pathStartPoint, point]
  );

  const finish = useMemo(
    () =>
      pathDestinationPoint?.lat &&
      point([pathDestinationPoint.lat, pathDestinationPoint.lng]),
    [pathDestinationPoint, point]
  );

  const path = useMemo(
    () =>
      begining && finish && pathfinder && pathfinder.findPath(begining, finish),
    [begining, finish, pathfinder]
  );

  const coords: any = useMemo(() => {
    if (path?.path?.length > 0) {
      return {
        type: "MultiLineString",
        coordinates: [
          [
            begining.geometry.coordinates,
            ...path?.path.map((coord: any) => coord),
            finish.geometry.coordinates,
          ],
        ],
      };
    }
  }, [path, begining, finish]);

  // ADD CUSTOM BACKGROUND TO DESTINATION AND START FLOORS/ELEVATORS
  const currentMarkerStart = useMemo(() => {
    if (
      startPoints?.length > 0 &&
      startPoints[0]?.closest?.layer &&
      startPoint
    ) {
      return findClosestMarker(map, startPoints, startPoint);
    }
  }, [startPoints, startPoint, map]);

  const currentMarkerDestination = useMemo(() => {
    if (
      destinationPoints?.length > 0 &&
      destinationPoints[0]?.closest?.layer &&
      destinationPoint
    ) {
      return findClosestMarker(map, destinationPoints, destinationPoint);
    }
  }, [destinationPoints, destinationPoint, map]);

  useEffect(() => {
    if (
      (type === "start" || type === "destination") &&
      stairsPoints &&
      stairsPoints[type] &&
      !stairsPoints[type]?.includes(currentMarkerStart?.id) &&
      !stairsPoints[type]?.includes(currentMarkerDestination?.id)
    ) {
      let elements = [];
      currentMarkerStart?.id && elements.push(currentMarkerStart?.id);
      currentMarkerDestination?.id &&
        elements.push(currentMarkerDestination?.id);

      elements.length > 0 &&
        setStairsPoints({
          ...stairsPoints,
          [type]: elements,
        });
    }
  }, [
    currentMarkerStart,
    currentMarkerDestination,
    setStairsPoints,
    stairsPoints,
    type,
  ]);

  // REMOVE CUSTOM BACKGROUND FROM STAIRS/ELEVATORS ON UNMOUNT
  useEffect(() => {
    return () => {
      setStairsPoints({
        destination: [],
        start: [],
      });
    };
  }, [setStairsPoints]);

  return (
    <>
      {!isSamePoint && (
        <>
          {coords && (
            <GeoJSON
              key={Math.random()}
              data={coords}
              style={{
                color: color,
                opacity: 1,
                weight: 6,
              }}
            />
          )}
          {/* {startPoint && <Marker position={[startPoint.lng, startPoint.lat]} />}

          {pathStartPoint && (
            <Marker position={[pathStartPoint.lng, pathStartPoint.lat]} />
          )}
          {destinationPoint && (
            <Marker position={[destinationPoint.lng, destinationPoint.lat]} />
          )}
          {pathDestinationPoint && (
            <Marker
              position={[pathDestinationPoint.lng, pathDestinationPoint.lat]}
            />
          )} */}

          {/* {route?.coordinates && (
            <GeoJSON
              key={Math.random()}
              data={route.coordinates}
              style={{
                color: "red",
                opacity: 1,
              }}
            />
          )} */}

          {startPoint && pathStartPoint && (
            <Polyline
              key={Math.random()}
              color={color}
              weight={6}
              opacity={1}
              positions={[
                [startPoint.lng, startPoint.lat],
                [pathStartPoint.lng, pathStartPoint.lat],
              ]}
            />
          )}
          {destinationPoint && pathDestinationPoint && (
            <Polyline
              key={Math.random()}
              color={color}
              weight={6}
              opacity={1}
              positions={[
                [destinationPoint.lng, destinationPoint.lat],
                [pathDestinationPoint.lng, pathDestinationPoint.lat],
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default Routes;
