import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { Dispatch } from "redux";
import { getCampus } from "../../redux/campus/actions";
import { selectCampus } from "../../redux/user/actions";
import { getMap } from "../../redux/maps/actions";
import { setMapPosition } from "../../redux/map/actions";
import { useParams } from "react-router";
import Box from "../../components/Box";
import Icon from "../../components/FormElements/Icons";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import { getDistance } from "../../services/positions";
import { IBuilding } from "../../interfaces";

const Campus: FunctionComponent = () => {
  const { id } = useParams();

  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";

  const dispatch: Dispatch = useDispatch();

  const campuses: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Campuses
  );

  const maps: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Maps
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const campus = useMemo(() => id && campuses?.byId[Number(id)], [
    campuses,
    id,
  ]);

  useEffect(() => {
    !campus && dispatch(getCampus(Number(id)));
    dispatch(selectCampus(Number(id)));
  }, [dispatch, id, campus]);

  const map = useMemo(
    () => id && campus?.data?.map_id && maps?.byId[campus.data.map_id],
    [campus, id, maps]
  );

  useEffect(() => {
    if (campus?.data?.map_id && !campus?.loading && !map) {
      dispatch(getMap(`${campus.data.map_id}/points`));
    }
  }, [campus, dispatch, map]);

  useEffect(() => {
    if (map?.data?.json_coordinates) {
      dispatch(setMapPosition(JSON.parse(map.data.json_coordinates)));
    }
  }, [dispatch, id, map]);

  const coords = useMemo(
    () =>
      buildings &&
      isResolved &&
      buildings?.data?.data
        ?.filter((building: IBuilding) => building?.map_id === map?.data?.id)
        ?.map((building: any) => building.coordinates.coordinates.flat(2))
        .flat(1),
    [buildings, map, isResolved]
  );

  const distance = useMemo(
    () =>
      coords &&
      getDistance(coords, userLocation, "Znajdujesz się na tym kampusie"),
    [userLocation, coords]
  );

  return (
    <section className="container element">
      <div className="campus scrollable">
        {campuses.byId[Number(id)]?.data && (
          <Box
            loading={
              campus?.loading ||
              map?.loading ||
              campuses?.loading ||
              maps?.loading
            }
            title={`Wróć`}
            childElement
          >
            <div className="campus__content">
              <div className="row">
                <div className="col-17">
                  <span className="campus__name font-700">
                    {campus?.data?.name}
                  </span>
                  <span className="campus__city">{campus?.data?.city}</span>
                </div>
                <div className="col-7">
                  <div className="campus__distance">
                    <Icon name="pin" />
                    <span className="font-700"> {distance}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-24">
                  <img src={map?.data?.image} alt={map?.data?.name} />
                </div>
              </div>
              <div className="row">
                <div className="col-24">
                  <div className="">{map?.data?.description}</div>
                </div>
              </div>
            </div>
          </Box>
        )}
      </div>
    </section>
  );
};

export default Campus;
