import React, {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  DefaultApiResult,
  UserInterface,
} from "../../redux/reducers";
import { getMap } from "../../redux/maps/actions";
import { Dispatch } from "redux";
import { IPoint, IBuilding, IRoom } from "../../interfaces";
import Input from "../../components/FormElements/Input";
import Checkbox from "../../components/FormElements/Checkbox";
import DataList from "../../components/FormElements/DataList";
import { GeolocationContext } from "../../contexts/GeolocationContext";
import { getDistance, getCenter } from "../../services/positions";

interface ISearch {
  short?: boolean;
  link?: string;
  defaultValue?: string;
  destination?: string;
}

const Search: FunctionComponent<ISearch> = ({
  short,
  link,
  defaultValue,
  destination,
}) => {
  const dispatch: Dispatch = useDispatch();
  const userLocation = useContext(GeolocationContext);
  const isResolved = userLocation?.status === "resolved";
  const [value, setValue] = useState("");
  const [onlyInCampus, setOnlyInCampus] = useState(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [filteredPoints, setFilteredPoints] = useState<Array<IPoint>>([]);
  const [currentPoints, setCurrentPoints] = useState<Array<IPoint>>([]);
  const [loading, setLoading] = useState(false);

  const maps: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Maps
  );

  const campuses: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Campuses
  );

  const user: UserInterface = useSelector<RootState, UserInterface>(
    (state) => state.User
  );

  const buildings: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Buildings
  );

  const rooms: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Rooms
  );

  const points: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const getPointcoordinate = useCallback(
    (data: any, type: string) => {
      return data?.map((el: any) => {
        return {
          ...el,
          coordinates_x: getCenter(el?.coordinates?.coordinates?.flat(2))?.lng,
          coordinates_y: getCenter(el?.coordinates?.coordinates?.flat(2))?.lat,
          building_name: el.building_id
            ? `${
                buildings?.data?.data.find(
                  (building: IBuilding) => building.id === el.building_id
                )?.name
              }`
            : "",
          type,
        };
      });
    },
    [buildings]
  );

  const campus = useMemo(
    () => user?.selectedCampus && campuses?.byId[user?.selectedCampus],
    [campuses, user]
  );

  const campusPoints = useMemo(
    () => maps.byId[campus?.data?.map_id]?.data?.points,
    [maps, campus]
  );

  useEffect(() => {
    if (campus?.data?.map_id && !campus?.loading && !campusPoints) {
      dispatch(getMap(`${campus.data.map_id}/points`));
    }
  }, [campus, dispatch, campusPoints]);

  const buildingsPoints = useMemo(
    () => buildings && getPointcoordinate(buildings?.data?.data, "building"),
    [buildings, getPointcoordinate]
  );

  const roomsPoints = useMemo(
    () => rooms && getPointcoordinate(rooms?.data?.data, "room"),
    [rooms, getPointcoordinate]
  );
  const pointsPoints = useMemo(() => points && points?.data?.data, [points]);

  const allPoints = useMemo(() => {
    if (roomsPoints && buildingsPoints && pointsPoints) {
      if (onlyInCampus && campusPoints) {
        const filtredBuildings = buildingsPoints.filter(
          (building: IBuilding) => building.map_id === campus?.data?.map_id
        );

        const filteredRooms = roomsPoints.filter((room: IRoom) =>
          filtredBuildings
            .map((building: IBuilding) => building.id)
            .includes(room.building_id)
        );

        return [...filtredBuildings, ...filteredRooms, ...campusPoints];
      } else {
        return [...buildingsPoints, ...roomsPoints, ...pointsPoints];
      }
    }
  }, [
    onlyInCampus,
    roomsPoints,
    buildingsPoints,
    pointsPoints,
    campusPoints,
    campus,
  ]);

  useEffect(() => {
    if (allPoints && allPoints?.length > 0 && value) {
      setLoading(true);
      const timer = setTimeout(() => {
        const values = value.toLocaleLowerCase().split(" ");
        const results: Array<IPoint> = allPoints.filter(
          (point: IPoint) =>
            values.filter((value: string) =>
              `${point.name}${point.description}${point?.building_name}`
                .toLocaleLowerCase()
                .includes(value)
            )?.length === values?.length
        );
        setFilteredPoints(results);
        setCurrentPoints([]);
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [value, allPoints]);

  useEffect(() => {
    if (currentPoints?.length < 1 && filteredPoints?.length > 0) {
      const pointsWithDistance: Array<IPoint> = filteredPoints.map(
        (point: IPoint) => {
          return {
            ...point,
            distance:
              point.coordinates_y && point.coordinates_x && isResolved
                ? getDistance(
                    [[point.coordinates_y, point.coordinates_x]],
                    userLocation,
                    "Zanjdujesz sie przy punkcie"
                  )
                : null,
          };
        }
      );
      setCurrentPoints(pointsWithDistance);
    }
  }, [filteredPoints, isResolved, userLocation, currentPoints]);

  const componentLoading =
    maps?.data?.loading ||
    campuses?.data?.loading ||
    points?.loading ||
    campusPoints?.loading;

  return (
    <div className="search">
      <div className="row">
        <div className="col-24">
          <Input
            isFocued={(e) => setShowOptions(e)}
            className="transparnet with-icon"
            type="text"
            changeValue={(e) => setValue(e)}
            value={value}
            icon={"search"}
            placeholder={short ? "Wybierz punkt początkowy" : "Wyszukaj obiekt"}
            autofocus
          />
          {!short && user?.selectedCampus && (
            <div className="search__result__heading">
              <Checkbox
                id={"onlyInCampus"}
                isChecked={onlyInCampus}
                onCheck={() => setOnlyInCampus(!onlyInCampus)}
                label="Szukaj tylko w obrębie wybranego kampusu"
              />
            </div>
          )}
        </div>
      </div>

      {
        <div className="search__result">
          {value && (
            <DataList
              data={showOptions ? currentPoints : []}
              loading={componentLoading || loading}
              container={"point"}
              link={"point"}
              msg={"Nie znaleziono pasujących punktów."}
            />
          )}
        </div>
      }
    </div>
  );
};

export default Search;
