import React, { FunctionComponent } from "react";

const ICONS = {
  position: (
    <svg
      data-name="Group 9"
      xmlns="http://www.w3.org/2000/svg"
      width="32.729"
      height="32.729"
      viewBox="0 0 32.729 32.729"
    >
      <path data-name="Path 2036" d="M0 0h32.729v32.729H0z" fill="none" />
      <path
        data-name="Path 2037"
        d="M28.557 15.001A12.266 12.266 0 0017.729 4.173V1.364h-2.728v2.809A12.266 12.266 0 004.173 15.001H1.364v2.727h2.809a12.266 12.266 0 0010.828 10.829v2.807h2.727v-2.807a12.266 12.266 0 0010.829-10.828h2.807v-2.728zm-12.193 10.91a9.546 9.546 0 119.547-9.547 9.539 9.539 0 01-9.547 9.547z"
        fill="#202326"
      />
      <path
        data-name="Path 2038"
        d="M16.365 8.756a3.442 3.442 0 11-3.442 3.442 3.438 3.438 0 013.442-3.442zm0 15.212a8.262 8.262 0 01-6.884-3.694c.034-2.284 4.589-3.535 6.884-3.535s6.85 1.251 6.884 3.535a8.262 8.262 0 01-6.884 3.694z"
        fill="#202326"
      />
      <path
        data-name="Path 2039"
        d="M7.078 6.932h18.574v18.572H7.078z"
        fill="none"
      />
    </svg>
  ),
  checked: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="352.62"
      height="352.62"
      viewBox="0 0 352.62 352.62"
    >
      <path d="M337.222 22.952c-15.912-8.568-33.66 7.956-44.064 17.748-23.867 23.256-44.063 50.184-66.708 74.664-25.092 26.928-48.348 53.856-74.052 80.173-14.688 14.688-30.6 30.6-40.392 48.96-22.032-21.421-41.004-44.677-65.484-63.648C28.774 167.385-.602 157.593.01 190.029c1.224 42.229 38.556 87.517 66.096 116.28 11.628 12.24 26.928 25.092 44.676 25.704 21.42 1.224 43.452-24.48 56.304-38.556 22.645-24.48 41.005-52.021 61.812-77.112 26.928-33.048 54.468-65.485 80.784-99.145 16.524-20.808 68.544-72.217 27.54-94.248zM26.937 187.581c-.612 0-1.224 0-2.448.611-2.448-.611-4.284-1.224-6.732-2.448 1.836-1.224 4.896-.612 9.18 1.837z" />
    </svg>
  ),
  search: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.7 32.7"
      width="30"
      height="30"
      fill="#fff"
    >
      <path
        id="Path_2020"
        d="M21.1 19.2h-1l-.4-.3c2.9-3.4 2.5-8.6-.9-11.5s-8.5-2.6-11.4.8-2.5 8.6.9 11.5c3.1 2.6 7.6 2.6 10.6 0l.3.4v1l6.3 6.3 1.9-1.9-6.3-6.3zm-7.5 0c-3.1 0-5.6-2.5-5.6-5.6S10.5 8 13.6 8s5.6 2.5 5.6 5.6c0 3.1-2.5 5.6-5.6 5.6z"
      />
    </svg>
  ),
  arrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 451.846 451.847"
    >
      <path
        fill="#fff"
        d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"
      />
    </svg>
  ),
  print: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M472.178 133.907h-54.303V35.132c0-9.425-7.641-17.067-17.067-17.067H111.192c-9.425 0-17.067 7.641-17.067 17.067v98.775H39.822C17.864 133.907 0 151.772 0 173.73v171.702c0 21.958 17.864 39.822 39.822 39.822h54.306v91.614c0 9.425 7.641 17.067 17.067 17.067h289.61c9.425 0 17.067-7.641 17.067-17.067v-91.614h54.306c21.958 0 39.822-17.864 39.822-39.822V173.73c0-21.957-17.864-39.823-39.822-39.823zm-343.92-81.708h255.483v81.708H128.258V52.199zm255.48 407.602H128.262V320.173h255.477c-.001 4.229-.001 136.421-.001 139.628zm94.129-114.368c0 3.137-2.552 5.689-5.689 5.689h-54.306v-48.014c0-9.425-7.641-17.067-17.067-17.067h-289.61c-9.425 0-17.067 7.641-17.067 17.067v48.014H39.822c-3.137 0-5.689-2.552-5.689-5.689V173.731c0-3.137 2.552-5.689 5.689-5.689h432.356c3.137 0 5.689 2.552 5.689 5.689v171.702z" />
      <path d="M400.808 199.988h-43.443c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h43.443c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067zM329.956 399.834H182.044c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h147.911c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.066-17.067zM329.956 346.006H182.044c-9.425 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067h147.911c9.425 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.066-17.067z" />
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.398"
      height="14.259"
      viewBox="0 0 14.398 14.259"
    >
      <g data-name="Group 22">
        <path
          data-name="Path 2065"
          d="M7.213 10.299l3.169-3.169H8.005V0H6.421v7.13H4.044z"
        />
        <path
          data-name="Path 2066"
          d="M12.813 0H9.59v1.575h3.224v11.116H1.584V1.575h3.253V0H1.584A1.589 1.589 0 000 1.584v11.091a1.589 1.589 0 001.584 1.584h11.229a1.589 1.589 0 001.585-1.584V1.584A1.589 1.589 0 0012.813 0z"
        />
      </g>
    </svg>
  ),
  save: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.333 469.333">
      <path d="M466.208 88.458L380.875 3.125c-2-2-4.708-3.125-7.542-3.125H42.667C19.146 0 0 19.135 0 42.667v384c0 23.531 19.146 42.667 42.667 42.667h384c23.521 0 42.667-19.135 42.667-42.667V96a10.6655 10.6655 0 00-3.126-7.542zM106.667 21.333h234.667v128c0 11.76-9.563 21.333-21.333 21.333H128c-11.771 0-21.333-9.573-21.333-21.333v-128zM384 448H85.333V256H384v192zm64-21.333c0 11.76-9.563 21.333-21.333 21.333h-21.333V245.333c0-5.896-4.771-10.667-10.667-10.667h-320c-5.896 0-10.667 4.771-10.667 10.667V448H42.667c-11.771 0-21.333-9.573-21.333-21.333v-384c0-11.76 9.563-21.333 21.333-21.333h42.667v128C85.333 172.865 104.479 192 128 192h192c23.521 0 42.667-19.135 42.667-42.667v-128h6.25L448 100.417v326.25z" />
      <path d="M266.667 149.333h42.667c5.896 0 10.667-4.771 10.667-10.667V53.333c0-5.896-4.771-10.667-10.667-10.667h-42.667c-5.896 0-10.667 4.771-10.667 10.667v85.333c0 5.896 4.771 10.667 10.667 10.667zM277.333 64h21.333v64h-21.333V64z" />
    </svg>
  ),
  qr: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 32.7 32.7"
    >
      <g id="Group_6" transform="translate(-3.2 -3.2)">
        <path
          id="Path_2023"
          d="M24.4 32.9v-2.4h6v-6h2.4v5.7c0 1.5-1.2 2.7-2.7 2.7h-5.7zM9 32.9c-1.5 0-2.7-1.2-2.7-2.7v-5.7h2.4v6h6v2.4H9zm21.4-18.2v-6h-6V6.3h5.7c1.5 0 2.7 1.2 2.7 2.7v5.7h-2.4zm-24.1 0V9c0-1.5 1.2-2.7 2.7-2.7h5.7v2.4h-6v6H6.3z"
        />
        <path id="Path_2024" d="M14.3 14.3h2.1v-2.1h-4.2v4.2h2.1v-2.1z" />
        <path id="Path_2025" d="M23.4 12.2h3.5v2.1h-3.5v-2.1z" />
        <path id="Path_2026" d="M26.9 22.7h-2.1v2.1h-1.7v2.1h3.8v-4.2z" />
        <path id="Path_2027" d="M16.4 24.8h-2.1v-2.1h-2.1v4.2h4.2v-2.1z" />
        <path id="Path_2028" d="M18.5 12.2h2.8v2.1h-2.8v-2.1z" />
        <path id="Path_2029" d="M26.9 16.4h-2.1v2.1h-1.7v2.1h3.8v-4.2z" />
        <path
          id="Path_2030"
          d="M16.4 22.7h2.4v4.2H21v-6.3h-2.4v-2.1H21v-2.1h-4.5v2.1h-4.2v2.1h4.2l-.1 2.1z"
        />
      </g>
    </svg>
  ),
  show: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.168"
      height="13.069"
      viewBox="0 0 19.168 13.069"
    >
      <path
        data-name="Path 989"
        d="M9.584 0A10.3 10.3 0 000 6.535a10.3 10.3 0 009.584 6.535 10.3 10.3 0 009.584-6.535A10.3 10.3 0 009.584 0zm0 10.891a4.358 4.358 0 01-4.356-4.356 4.358 4.358 0 014.356-4.356 4.358 4.358 0 014.356 4.356 4.358 4.358 0 01-4.356 4.356zm0-6.97A2.61 2.61 0 006.97 6.535a2.61 2.61 0 002.614 2.614A2.61 2.61 0 0012.2 6.535a2.61 2.61 0 00-2.616-2.614z"
        fill="#b2b2b2"
      />
    </svg>
  ),
  hide: (
    <svg
      data-name="Group 20"
      xmlns="http://www.w3.org/2000/svg"
      width="19.168"
      height="13.07"
      viewBox="0 0 19.168 13.07"
    >
      <path
        data-name="Path 2063"
        d="M17.885 4.217l-3.979 2.655a4.279 4.279 0 01-5.671 3.784l-2.5 1.666a10.271 10.271 0 003.846.748 10.3 10.3 0 009.584-6.535 10.321 10.321 0 00-1.28-2.318z"
        fill="#b2b2b2"
      />
      <path
        data-name="Path 2064"
        d="M16.962.024L14.824 1.45A10.266 10.266 0 000 6.535 10.234 10.234 0 002.162 9.9L0 11.341l1.137 1.7L18.1 1.728zM5.228 6.535a4.358 4.358 0 014.356-4.356 4.289 4.289 0 012.7.964l-1.56 1.043a2.56 2.56 0 00-1.13-.265h-.01A2.611 2.611 0 006.97 6.527v.008c0 .05.016.094.019.143L5.407 7.734a4.166 4.166 0 01-.179-1.199z"
        fill="#b2b2b2"
      />
    </svg>
  ),
  pin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.647"
      height="16.5"
      viewBox="0 0 11.647 16.5"
    >
      <path
        data-name="Path 2042"
        d="M11.65 5.824a5.824 5.824 0 10-11.647 0c0 4.368 5.824 10.676 5.824 10.676s5.823-6.309 5.823-10.676zm-7.765 0a1.941 1.941 0 111.941 1.941 1.947 1.947 0 01-1.944-1.941z"
        fill="#0091ff"
      />
    </svg>
  ),
  search_bar: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.445"
      height="23.445"
      viewBox="0 0 23.445 23.445"
    >
      <path
        data-name="Path 2020"
        d="M15.142 13.677h-.772l-.274-.264a6.359 6.359 0 10-.684.684l.264.274v.772l4.884 4.875 1.456-1.456zm-5.861 0a4.4 4.4 0 114.4-4.4 4.39 4.39 0 01-4.4 4.4z"
        fill="#373740"
      />
      <path data-name="Path 2021" d="M0 0h23.445v23.445H0z" fill="none" />
    </svg>
  ),
  cam_frame: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="234.679"
      height="234.68"
      viewBox="0 0 234.679 234.68"
    >
      <path
        data-name="Union 3"
        d="M175.714 234.18h0a2.219 2.219 0 01-2.214-2.219 2.219 2.219 0 012.214-2.219h44.084a9.953 9.953 0 009.941-9.941v-44.164h.008a2.216 2.216 0 012.213-2.137 2.215 2.215 0 012.211 2.137h.008V219.8a14.423 14.423 0 01-14.379 14.38zm-160.833 0A14.423 14.423 0 01.5 219.8v-44.086h0a2.219 2.219 0 012.22-2.214 2.219 2.219 0 012.219 2.214h0V219.8a9.953 9.953 0 009.941 9.941h44.162v.008a2.215 2.215 0 012.136 2.211 2.215 2.215 0 01-2.136 2.212v.008zm214.86-175.214h0V14.881A9.953 9.953 0 00219.8 4.94h-44.161v-.008A2.214 2.214 0 01173.5 2.72a2.214 2.214 0 012.137-2.212V.5h44.161a14.423 14.423 0 0114.381 14.381v44.085a2.22 2.22 0 01-2.219 2.214 2.219 2.219 0 01-2.22-2.214zM.508 59.042H.5V14.881A14.423 14.423 0 0114.881.5h44.084a2.219 2.219 0 012.214 2.219 2.219 2.219 0 01-2.214 2.219H14.881a9.953 9.953 0 00-9.941 9.941v44.163h-.008A2.216 2.216 0 012.72 61.18a2.216 2.216 0 01-2.212-2.138z"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  close_background: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <circle cx="17.4" cy="17.2" r="15.5" fill="#ccc" />
      <path
        d="M22.1 23.7L17.4 19l-4.6 4.6c-.5.5-1.3.5-1.9 0-.5-.5-.5-1.3 0-1.9l4.6-4.6-4.5-4.5c-.5-.5-.5-1.3 0-1.9.5-.5 1.3-.5 1.9 0l4.6 4.6 4.6-4.6c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.9l-4.6 4.6 4.6 4.6c.5.5.5 1.3 0 1.8-.2.3-.6.4-.9.4-.4 0-.7-.1-.9-.3z"
        fill="#fff"
      />
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.678"
      height="17.678"
      viewBox="0 0 17.678 17.678"
    >
      <path
        data-name="Union 2"
        d="M8.839 10.253l-7.424 7.425L0 16.264l7.425-7.425L0 1.414 1.415 0l7.424 7.425L16.264 0l1.414 1.414-7.425 7.425 7.425 7.425-1.414 1.414z"
        fill="#fff"
      />
    </svg>
  ),
  flash_light: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g data-name="Group 13">
        <path data-name="Rectangle 156" fill="none" d="M0 0h41v41H0z" />
      </g>
      <g data-name="Group 16">
        <g data-name="Group 15">
          <g data-name="Group 14">
            <path
              data-name="Path 2060"
              d="M10.114 23.783l5.057 5.057v8.428h10.114V28.84l5.057-5.057v-8.428H10.114zm8.428-20.228h3.371v5.057h-3.371zM5.9 10.095l2.377-2.376 3.573 3.573-2.376 2.377zm22.689 1.2l3.574-3.574 2.376 2.374-3.557 3.574z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  my_position: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <g data-name="Group 21" transform="translate(-1916 59)">
        <circle
          data-name="Ellipse 4"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(1916 -59)"
          fill="#ce1034"
          opacity=".173"
        />
        <g data-name="Warstwa 1">
          <g data-name="Group 10">
            <path
              data-name="Path 2046"
              d="M1938.311-48.709a12.129 12.129 0 00-3.115 23.852l3.115 3.115 3.115-3.115a12.129 12.129 0 00-3.115-23.852z"
              fill="#ce1034"
            />
            <path
              data-name="Path 2047"
              d="M1938.311-48.709a12.129 12.129 0 013.115 23.852l-3.115 3.113-3.115-3.115a12.129 12.129 0 013.115-23.85m0-1.685a13.814 13.814 0 00-4 27.04l2.8 2.8 1.192 1.192 1.192-1.192 2.8-2.8a13.814 13.814 0 00-4-27.04z"
              fill="#fff"
            />
          </g>
          <path
            data-name="Path 2048"
            d="M1922.131-51.405h32.36v32.36h-32.36z"
            fill="none"
          />
        </g>
        <g data-name="Warstwa 2">
          <path
            data-name="Path 2049"
            d="M1938.311-46.378a4.567 4.567 0 11-4.567 4.567 4.561 4.561 0 014.567-4.567zm0 20.181a10.964 10.964 0 01-9.134-4.9c.046-3.03 6.089-4.688 9.134-4.688s9.088 1.658 9.134 4.688a10.964 10.964 0 01-9.134 4.9z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  ),
  menu: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="30"
      height="30"
    >
      <path d="M0 0h30v30H0V0z" fill="none" />
      <path
        d="M3.6 22.6h22.8v-2.5H3.6v2.5zm0-6.3h22.8v-2.5H3.6v2.5zm0-8.9v2.5h22.8V7.4H3.6z"
        fill="#fff"
      />
    </svg>
  ),
  select_campus: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <path
        data-name="Path 2042"
        d="M22.5 10a7.5 7.5 0 00-15 0c0 5.625 7.5 13.75 7.5 13.75s7.5-8.125 7.5-13.75zm-10 0a2.5 2.5 0 112.5 2.5 2.507 2.507 0 01-2.5-2.5zM6.25 25v2.5h17.5V25z"
        fill="#fff"
      />
      <path data-name="Path 2043" d="M0 0h30v30H0z" fill="none" />
    </svg>
  ),
  add_to_fav: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.398"
      height="19.398"
      viewBox="0 0 19.398 19.398"
    >
      <g data-name="Group 31">
        <g data-name="Group 28">
          <g data-name="Group 27">
            <path
              data-name="Path 2073"
              d="M15.934 0H3.464A3.468 3.468 0 000 3.464v12.47A3.468 3.468 0 003.464 19.4h12.47a3.468 3.468 0 003.464-3.464V3.466A3.468 3.468 0 0015.934 0zm2.078 15.934a2.078 2.078 0 01-2.078 2.078H3.464a2.078 2.078 0 01-2.078-2.078V3.464a2.078 2.078 0 012.078-2.078h12.47a2.078 2.078 0 012.078 2.078z"
              fill="#0091ff"
            />
          </g>
        </g>
        <g data-name="Group 30">
          <g data-name="Group 29">
            <path
              data-name="Path 2074"
              d="M13.163 9.006h-2.768V6.235a.693.693 0 10-1.386 0v2.771H6.235a.693.693 0 100 1.386h2.771v2.771a.693.693 0 001.386 0v-2.768h2.771a.693.693 0 100-1.386z"
              fill="#0091ff"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  copy_url: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g data-name="Group 26">
        <g data-name="Group 25">
          <g data-name="Group 24" fill="#0091ff">
            <path
              data-name="Path 2071"
              d="M7.876 14.482l-2.238 2.239a2.375 2.375 0 11-3.359-3.358l4.478-4.478a2.374 2.374 0 013.359 0 .792.792 0 001.119-1.12 3.957 3.957 0 00-5.6 0l-4.479 4.478a3.96 3.96 0 005.6 5.6l2.237-2.242a.792.792 0 00-1.12-1.12z"
            />
            <path
              data-name="Path 2072"
              d="M17.841 1.159a3.96 3.96 0 00-5.6 0L9.555 3.845a.792.792 0 001.12 1.12l2.686-2.686a2.375 2.375 0 013.359 3.358l-4.926 4.926a2.375 2.375 0 01-3.358 0 .792.792 0 00-1.12 1.12 3.958 3.958 0 005.6 0l4.926-4.926a3.958 3.958 0 00-.001-5.598z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  distance: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.6 16.5">
      <path
        d="M11.5 5.9C11.5 2.8 9 .2 5.9.1S.2 2.6.1 5.8v.1c0 4.3 5.7 10.4 5.7 10.4s5.7-6.1 5.7-10.4zm-7.6 0c0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9c-1 0-1.9-.8-1.9-1.9z"
        fill="#0091ff"
      />
    </svg>
  ),
  options: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="14"
      viewBox="0 0 3 14"
    >
      <defs>
        <clipPath>
          <path fill="none" d="M0 0h3v14H0z" />
        </clipPath>
      </defs>
      <g data-name="Repeat Grid 7" clipPath="url(#a)">
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          fill="#202326"
        />
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(0 5)"
          fill="#202326"
        />
        <circle
          data-name="Ellipse 10"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(0 10)"
          fill="#202326"
        />
      </g>
    </svg>
  ),
  events: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 23.1 24.9"
      width="20"
      height="20"
      fill="#fff"
    >
      <g id="Group_36" transform="translate(-164.036 -235.506)">
        <path
          id="Path_2083"
          d="M183.8 237.4v-.8c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1v.7h-11.1v-.7c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1v.7h-.1c-2.3 0-4.1 1.8-4.1 4.1v14.8c0 2.3 1.8 4.1 4.1 4.1H183c2.3 0 4.1-1.8 4.1-4.1v-14.8c0-1.9-1.4-3.6-3.3-4zm-.8 21.4h-14.8c-1.4 0-2.5-1.1-2.5-2.5v-13h19.8v13c0 1.4-1.2 2.5-2.5 2.5z"
        />
        <path
          id="Path_2084"
          d="M180.4 246.8h-9.6c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.6c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2z"
        />
        <path
          id="Path_2085"
          d="M176.8 251.6h-6c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h6c.7 0 1.2-.5 1.2-1.2 0-.6-.6-1.2-1.2-1.2z"
        />
      </g>
    </svg>
  ),
  fav_points: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 23.1 24.9"
      width="20"
      height="20"
      fill="#fff"
    >
      <g id="Group_34" transform="translate(-164.036 -238.934)">
        <g id="Group_33" transform="translate(164.036 238.934)">
          <g id="Group_32">
            <path
              id="Path_2077"
              d="M18.9.9H4.1C1.8.9 0 2.8 0 5v14.8C0 22.1 1.8 24 4.1 24h14.8c2.3 0 4.1-1.8 4.1-4.1V5c.1-2.2-1.8-4.1-4.1-4.1zm2.5 19c0 1.4-1.1 2.5-2.5 2.5H4.1c-1.4 0-2.5-1.1-2.5-2.5V5c0-1.4 1.1-2.5 2.5-2.5h14.8c1.4 0 2.5 1.1 2.5 2.5v14.9z"
            />
          </g>
        </g>
        <path
          id="Path_2078"
          d="M175.6 243.1c-3.2 0-5.8 2.6-5.8 5.8 0 4.4 5.8 10.7 5.8 10.7s5.8-6.3 5.8-10.7c0-3.2-2.6-5.8-5.8-5.8zm0 8.3c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6-.1 1.4-1.2 2.6-2.6 2.6z"
        />
      </g>
    </svg>
  ),
  hide_menu: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 22.903 22.903"
    >
      <path
        data-name="Path 2075"
        d="M11.452 0L9.433 2.018l7.988 8H0v2.863h17.421l-7.988 8 2.019 2.019L22.9 11.452z"
        fill="#fff"
      />
    </svg>
  ),
  log_in: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23.1 24.9"
    >
      <path d="M30.4-.6h25v25h-25v-25z" fill="none" />
      <path
        d="M11.5 2C5.8 2 1.1 6.7 1.1 12.4c0 5.8 4.7 10.4 10.4 10.4 5.8 0 10.4-4.7 10.4-10.4C22 6.7 17.3 2 11.5 2zm0 2.9c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5S8 10.3 8 8.4s1.6-3.5 3.5-3.5zm0 16.3c-2.9 0-5.5-1.5-7-3.8 0-2.3 4.7-3.6 7-3.6s7 1.3 7 3.6c-1.4 2.3-4 3.8-7 3.8z"
        fill="#fff"
      />
    </svg>
  ),
  log_out: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23.1 24.9"
    >
      <path
        d="M11.3 1.9c-.6 0-1.2.5-1.2 1.2v9.2c0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2V3.1c-.1-.7-.6-1.2-1.2-1.2zm5.9 3.3c-.4.4-.4 1.2 0 1.6 1.3 1.4 2.1 3.3 2.2 5.3.1 4.5-3.5 8.2-8 8.3-4.5.1-8.1-3.5-8.2-8 0-2.1.7-4.1 2.2-5.6.4-.5.4-1.2 0-1.6-.5-.4-1.2-.4-1.6 0C1.9 7.1.9 9.5.9 12 .8 17.7 5.3 22.4 11 22.7c5.7.2 10.5-4.3 10.7-10.1v-.3c0-2.6-1-5.1-2.8-7.1-.5-.4-1.2-.4-1.7 0z"
        fill="#fff"
      />
    </svg>
  ),
  finish: (
    <svg viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero">
        <path d="M14.678 57.95l1.068-.298a1.931 1.931 0 001.34-2.38L4.878 11.585a2.414 2.414 0 00-2.975-1.675l-.138.038A2.414 2.414 0 00.09 12.922L12.299 56.61a1.931 1.931 0 002.379 1.34zM57.67 27.42a46.256 46.256 0 01-10.64-7.32.95.95 0 01-.27-.97A136.854 136.854 0 0050.27.95c.12-1.02-.43-1.32-1.01-.62-11.38 13.61-31.07-2.49-42.79 9.88.14.263.251.542.33.83l7.92 28.36c11.74-12.22 31.36 3.78 42.72-9.8.58-.7.69-1.98.23-2.18z" />
      </g>
    </svg>
  ),
  minus: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M492 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h472c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  ),
  plus: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z" />
    </svg>
  ),
  door: (
    <svg
      width="20"
      height="20"
      viewBox="-80 0 480 480"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M312 0H8a8 8 0 00-8 8v464a8 8 0 008 8h304a8 8 0 008-8V8a8 8 0 00-8-8zm-40 464H48V48h224zm32 0h-16V40a8 8 0 00-8-8H40a8 8 0 00-8 8v424H16V16h288zm0 0" />
      <path d="M72 288h32a8 8 0 008-8v-16h24v-16h-24v-48a8 8 0 00-8-8H72a8 8 0 00-8 8v80a8 8 0 008 8zm8-80h16v40h-8v16h8v8H80zm0 0M112 144h96a8 8 0 008-8v-32a8 8 0 00-8-8h-96a8 8 0 00-8 8v32a8 8 0 008 8zm8-32h80v16h-80zm0 0" />
    </svg>
  ),
  elevator: (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490.667 490.667"
    >
      <path d="M480 0H10.667C4.779 0 0 4.779 0 10.667V480c0 5.888 4.779 10.667 10.667 10.667H96c5.888 0 10.667-4.779 10.667-10.667v-10.667H384V480c0 5.888 4.779 10.667 10.667 10.667H480c5.888 0 10.667-4.779 10.667-10.667V10.667C490.667 4.779 485.888 0 480 0zm-10.667 469.333h-64v-10.667c0-5.888-4.779-10.667-10.667-10.667H96c-5.888 0-10.667 4.779-10.667 10.667v10.667h-64v-448h448v448z" />
      <path d="M394.667 106.667H96c-5.888 0-10.667 4.779-10.667 10.667v341.333c0 5.888 4.779 10.667 10.667 10.667h298.667c5.888 0 10.667-4.779 10.667-10.667V117.333c-.001-5.888-4.779-10.666-10.667-10.666zM384 448H106.667V128H384v320z" />
      <path d="M245.333 106.667c-5.888 0-10.667 4.779-10.667 10.667v341.333c0 5.888 4.779 10.667 10.667 10.667S256 464.555 256 458.667V117.333c0-5.888-4.779-10.666-10.667-10.666z" />
      <circle cx="437.333" cy="224" r="10.667" />
      <circle cx="437.333" cy="266.667" r="10.667" />
      <path d="M210.219 67.136l-21.333-21.333c-4.16-4.16-10.923-4.16-15.083 0L152.47 67.136c-4.16 4.16-4.16 10.923 0 15.083s10.923 4.16 15.083 0l13.781-13.803 13.781 13.803a10.716 10.716 0 0015.104 0c4.16-4.16 4.16-10.923 0-15.083zM338.197 45.803c-4.16-4.16-10.923-4.16-15.083 0l-13.781 13.781-13.803-13.781c-4.16-4.16-10.923-4.16-15.083 0s-4.16 10.923 0 15.083l21.333 21.333c2.091 2.069 4.821 3.115 7.552 3.115s5.461-1.045 7.531-3.115l21.333-21.333c4.161-4.161 4.161-10.923.001-15.083z" />
    </svg>
  ),
  stairs: (
    <svg
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M501.333 426.667H64V384h74.667a10.66 10.66 0 0010.667-10.667V320H224a10.66 10.66 0 0010.667-10.667V256h74.667a10.66 10.66 0 0010.667-10.667V192h74.667a10.66 10.66 0 0010.667-10.667V128H480a10.66 10.66 0 0010.667-10.667A10.66 10.66 0 00480 106.666h-85.333A10.66 10.66 0 00384 117.333v53.333h-74.667a10.66 10.66 0 00-10.667 10.667v53.333H224a10.66 10.66 0 00-10.667 10.667v53.333h-74.667a10.66 10.66 0 00-10.667 10.667v53.333H53.333a10.66 10.66 0 00-10.667 10.667v53.333h-32A10.662 10.662 0 000 437.333 10.66 10.66 0 0010.667 448h490.667a10.66 10.66 0 0010.667-10.667 10.662 10.662 0 00-10.668-10.666zM74.667 234.638c2.5 0 5-.865 7.021-2.635l141.094-123.457-8.906 26.717c-1.854 5.594 1.167 11.635 6.75 13.49 1.125.375 2.25.552 3.375.552 4.458 0 8.625-2.823 10.125-7.292l21.333-64a10.637 10.637 0 00-2.021-10.302c-2.417-2.844-6.188-4.125-9.854-3.594l-64 10.667c-5.813.969-9.75 6.458-8.771 12.271s6.604 9.823 12.271 8.771l26.971-4.493L67.646 215.94c-4.438 3.885-4.896 10.625-1 15.052a10.607 10.607 0 008.021 3.646z" />
    </svg>
  ),
  navigate: (
    <svg
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 494.148 494.148"
    >
      <path d="M502.978 210.683L20.979 1.243A15 15 0 001.244 20.979l209.44 482a15 15 0 0028.101-1.591l44.912-146.847a106.584 106.584 0 0170.844-70.844l146.846-44.912a15 15 0 001.591-28.102z" />
    </svg>
  ),
  start: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.148 494.148">
      <path d="M405.284 201.188L130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z" />
    </svg>
  ),
};

interface IIcon {
  name:
    | "position"
    | "checked"
    | "search"
    | "arrow"
    | "print"
    | "download"
    | "save"
    | "qr"
    | "show"
    | "hide"
    | "pin"
    | "search_bar"
    | "cam_frame"
    | "close_background"
    | "close"
    | "flash_light"
    | "my_position"
    | "menu"
    | "select_campus"
    | "add_to_fav"
    | "copy_url"
    | "distance"
    | "options"
    | "events"
    | "fav_points"
    | "hide_menu"
    | "log_in"
    | "log_out"
    | "finish"
    | "plus"
    | "minus"
    | "door"
    | "elevator"
    | "stairs"
    | "navigate"
    | "start";
}

const Icon: FunctionComponent<IIcon> = ({ name }) => (
  <span className="icon">{ICONS[name]}</span>
);

export default Icon;
