import React, { useState, FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../FormElements/Icons";
import Button from "../../FormElements/Button";

const Navigation: FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false);
  const active = isOpen ? "active" : "";

  return (
    <nav className={`nav ${active}`}>
      <button
        aria-label="Otwórz lub zamknij menu"
        onClick={() => setOpen(!isOpen)}
        className={`hamburger ${active}`}
      >
        <Icon name={active ? "hide_menu" : "menu"} />
      </button>

      <div className={`navigation row ${active}`}>
        <ul className="ul">
          <li onClick={() => setOpen(false)} className="li">
            <NavLink to="/events">
              <Icon name="events" />
              <span>Wydarzenia</span>
            </NavLink>
          </li>
          <li onClick={() => setOpen(false)} className="li">
            <NavLink to="/favourite">
              <Icon name="fav_points" />
              <span>Zapisane punkty</span>
            </NavLink>
          </li>

          {false ? (
            <li onClick={() => setOpen(false)} className="li li--with-border">
              <div className="current-user-wrapper">
                <div className="current-user row margin-0">
                  <Icon name="log_in" />
                  <div>
                    <div>Zalogowano</div>
                    <div>Joanna Kowalska</div>
                  </div>
                </div>

                {/* TODO when api ready */}

                <Button
                  className={`logout-btn col-24 ${active}`}
                  onClick={() => console.log("logout btn click")}
                >
                  <Icon name="log_out" />
                  <span>Wyloguj</span>
                </Button>
              </div>
            </li>
          ) : (
            <li onClick={() => setOpen(false)} className="li li--with-border">
              <NavLink to="/login">
                <Icon name="log_in" />
                <span>Zaloguj</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
