import Map from "./map/reducer";
import User from "./user/reducers";
import ConvertedEntrances from "./entrances/reuder";
export interface RootState {
  Campuses: DefaultApiResult;
  SelectedCampus: DefaultApiResult;
  Maps: DefaultApiResult;
  SelectedMap: DefaultApiResult;
  Events: DefaultApiResult;
  SelectedEvent: DefaultApiResult;
  Questions: DefaultApiResult;
  SelectedQuestion: DefaultApiResult;
  Answers: DefaultApiResult;
  Buildings: DefaultApiResult;
  Building: DefaultApiResult;
  Floors: DefaultApiResult;
  Rooms: DefaultApiResult;
  Points: DefaultApiResult;
  SelectedPoint: DefaultApiResult;
  User: UserInterface;
  Map: MapInterface;
  Entrances: DefaultApiResult;
  Routes: DefaultApiResult;
  ConvertedEntrances: any;
  Settings: DefaultApiResult;
}

// DEFAULT
export interface DefaultApiResult {
  loading: boolean;
  error: any;
  data: any;
  byId: any;
}

export interface DefaultApiAction {
  id?: string | number | any;
  type: string;
  error?: any;
  payload?: any;
  data?: DefaultApiAction;
  buildingId?: number;
  floorId?: number;
  num?: string;
}

// MAP
export interface MapInterface {
  position?: any;
  bounds?: object;
  maxBounds?: Array<any>;
  userPosition?: object;
  loading?: boolean;
}

// USER
export interface UserInterface {
  selectedCampus: number | string | null;
  error: boolean;
  loading: boolean;
  favouritePoints: Array<any>;
  // TODO ADD USER DATA ETC WHEN API READY
}

function createReducer(prefix: string) {
  const INIT_STATE = {
    loading: false,
    error: false,
    data: {},
    byId: {},
  };

  return function reducer(
    state: DefaultApiResult = INIT_STATE,
    action: DefaultApiAction
  ): DefaultApiResult {
    switch (action.type) {
      case `FETCH_${prefix}`:
        return {
          ...state,
          loading: true,
          error: false,
        };

      case `FETCH_${prefix}_SUCCESS`:
        return {
          ...state,
          error: false,
          loading: false,
          data: action.payload,
        };
      case `FETCH_${prefix}_FAILURE`:
        return {
          ...state,
          loading: false,
          error: true,
        };

      case `GET_${prefix}`:
        return {
          ...state,
          loading: true,
          error: false,
          byId: {
            ...state.byId,
            [action.id]: state.byId[action.id]
              ? { ...state.byId[action.id], loading: true, error: false }
              : { loading: true, error: false },
          },
        };

      case `GET_${prefix}_SUCCESS`:
        return {
          ...state,
          error: false,
          loading: false,
          byId: {
            ...state.byId,
            [action.payload.data.id]: {
              ...state.byId[action.payload.data.id],
              ...action.payload,
              loading: false,
              error: false,
            },
          },
        };

      case `GET_${prefix}_FAILURE`:
        return {
          ...state,
          loading: false,
          error: action.payload,
          byId: {
            ...state.byId,
            [action?.data?.id]: {
              ...state.byId[action?.data?.id],
              loading: false,
              error: true,
            },
          },
        };
    }

    return state;
  };
}

export default {
  Campuses: createReducer("CAMPUSES"),
  Maps: createReducer("MAPS"),
  SelectedMap: createReducer("MAP"),
  Events: createReducer("OCCASIONAL_EVENTS"),
  Questions: createReducer("QUESTIONS"),
  Answers: createReducer("QUESTION_ANSWERS"),
  Buildings: createReducer("BUILDINGS"),
  Floors: createReducer("FLOORS"),
  Rooms: createReducer("ROOMS"),
  Points: createReducer("POINTS"),
  Entrances: createReducer("ENTRANCES"),
  Routes: createReducer("ROUTES"),
  Map: Map,
  User: User,
  ConvertedEntrances: ConvertedEntrances,
  Settings: createReducer("SETTINGS"),
};
