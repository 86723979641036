import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { Dispatch } from "redux";
import { getEvent } from "../../redux/events/actions";
import { useParams } from "react-router";
import Box from "../../components/Box";
import Icon from "../../components/FormElements/Icons";
import { EventContext, IEventContext } from "../../contexts/EventContext";
import { MapContext, IMapContext } from "../../contexts/MapContext";
import { getBounds } from "../../services/positions";
import { IPoint } from "../../interfaces";
import { Link } from "react-router-dom";

const Event: FunctionComponent = () => {
  const { id } = useParams();
  const dispatch: Dispatch = useDispatch();

  const { mapInstance } = useContext<IMapContext>(MapContext);

  const { selectedEvent, setSelectedEvent } = useContext<IEventContext>(
    EventContext
  );

  const events: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Events
  );

  const event = useMemo(() => events?.byId[Number(id)], [events, id]);

  useEffect(() => {
    !event && dispatch(getEvent(Number(id)));
  }, [dispatch, id, event]);

  useEffect(() => {
    if (event?.data?.id) {
      event.data.id !== selectedEvent && setSelectedEvent(event.data);
    }
  }, [event, setSelectedEvent, selectedEvent]);

  useEffect(() => {
    if (event?.data?.points?.length > 0) {
      const bounds = getBounds(
        event?.data?.points.map((point: any) => [
          point.coordinates_x,
          point.coordinates_y,
        ])
      );
      mapInstance && mapInstance.fitBounds(bounds);
    }
  }, [event, mapInstance]);

  const formatDate = (from: string | undefined, to: string | undefined) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const dateFrom =
      from && new Date(from).toLocaleDateString("pl-Pl", options);
    const dateTo = to && new Date(to).toLocaleDateString("pl-Pl", options);

    return `${dateFrom} - ${dateTo}`;
  };

  return (
    <section className="container element">
      <div className="scrollable">
        <Box
          childElement
          loading={event?.loading || events?.loading}
          title={`Wróć`}
        >
          {event?.data && (
            <div className="event row">
              <div className="col-24">
                <span className="event_name font-700 uppercase">
                  {event?.data?.name || ""}
                </span>
                <div className="event__date">
                  <Icon name="events" />
                  {formatDate(event.data.date_from, event.data?.date_to)}
                </div>
                {selectedEvent?.points && selectedEvent?.points?.length > 0 && (
                  <div className="event__points">
                    <span className="font-600 event__points--title">
                      Punkty wydarzenia:
                    </span>
                    <ul>
                      {selectedEvent.points.map(
                        (point: IPoint, row: number) => {
                          return (
                            <li key={row}>
                              <Link
                                aria-label="Wyświetl informacje o punkcie i pokaż go na mapie"
                                to={`/point/${point.id}${
                                  point?.floor_id
                                    ? `?floor=${point.floor_id}`
                                    : ""
                                }`}
                              >
                                <img alt={point.name} src={point.emblem} />
                                {point.name}
                              </Link>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                )}
                <span
                  className="event__content"
                  dangerouslySetInnerHTML={{
                    __html: event.data.description,
                  }}
                ></span>
              </div>
            </div>
          )}
        </Box>
      </div>
    </section>
  );
};

export default Event;
