import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { getPoint } from "../../redux/points/actions";
import Box from "../../components/Box";
import PointDescription from "./../../components/PointDescription";

const Point: FunctionComponent = () => {
  const dispatch: Dispatch = useDispatch();
  const { id } = useParams();

  const points: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Points
  );
  const point = useMemo(() => points?.byId[Number(id)], [id, points]);

  useEffect(() => {
    !point && dispatch(getPoint(Number(id)));
  }, [dispatch, id, point]);

  return (
    <section className="container element">
      <div className="point scrollable">
        <Box loading={point?.loading} title={"Wróć"} childElement>
          {point?.data && <PointDescription data={point.data} type={"point"} />}
        </Box>
      </div>
    </section>
  );
};

export default Point;
