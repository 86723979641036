import L, { LatLng } from "leaflet";
import "leaflet-routing-machine";
import { getCenter, findClosest } from "../../services/positions";
import { IEntrance, IPoint, IFloor } from "../../interfaces";
import { DefaultApiResult } from "./../../redux/reducers";
let prevControl: any = null;
let prevPolyline: any = null;

export const removePaths = (map: L.Map | null) => {
  if (map) {
    prevControl && map.removeControl(prevControl);
    prevPolyline && map.removeControl(prevPolyline);
  }
};

export const renderPath = (
  map: L.Map | undefined | null,
  destination: IPoint,
  start?: any,
  history?: any,
  entrances?: DefaultApiResult,
  allFloors?: DefaultApiResult,
  routeColor?: string
) => {
  const getLatLng = (data: any) => {
    if (data?.coordinates?.length > 0) {
      return {
        lat: getCenter(data.coordinates.flat(2)).lat,
        lng: getCenter(data.coordinates.flat(2)).lng,
      };
    } else if (data?.coordinates_x && data?.coordinates_y) {
      return {
        lat: data.coordinates_y,
        lng: data.coordinates_x,
      };
    } else if (data?.lng) {
      return {
        lat: data.lng,
        lng: data.lat,
      };
    }
  };

  const getFloor = (data: IPoint) => {
    if (allFloors && data) {
      return allFloors?.data?.data?.find(
        (floor: IFloor) => floor?.id === data?.floor_id
      );
    }
  };

  let destinationEntrances;
  let startEntrances;

  //FIND CLOSEST DOOR TO DESTINATION POINT
  if (
    entrances?.data?.data.find(
      (entrance: IEntrance) =>
        entrance.type === "outside" &&
        entrance.floor_id === destination.floor_id
    )
  ) {
    destinationEntrances = entrances?.data?.data
      .filter((entrance: IEntrance) => {
        return (
          entrance.type === "outside" &&
          entrance.building_id === destination?.building_id &&
          entrance.floor_id === destination.floor_id
        );
      })
      .map((entrance: IEntrance) =>
        getCenter(entrance?.coordinates?.coordinates.flat(2))
      );
  } else {
    const exits = entrances?.data?.data?.filter((entrance: IPoint) => {
      return (
        entrance.type === "outside" &&
        entrance.building_id === destination?.building_id
      );
    });

    const closestFloor =
      exits?.length > 0 &&
      exits
        ?.map((exit: IPoint) => getFloor(exit).level)
        ?.reduce((a: number, b: number) => {
          return Math.abs(b - getFloor(destination)?.level) <
            Math.abs(a - getFloor(destination)?.level)
            ? b
            : a;
        });

    destinationEntrances =
      exits?.length > 0 &&
      exits
        .filter((entrance: IPoint) => {
          return getFloor(entrance).level === closestFloor;
        })
        .map((entrance: IEntrance) =>
          getCenter(entrance?.coordinates?.coordinates.flat(2))
        );
  }

  //FIND CLOSEST DOOR TO START POINT
  if (
    entrances?.data?.data.find(
      (entrance: IEntrance) =>
        entrance.type === "outside" && entrance.floor_id === start.floor_id
    )
  ) {
    startEntrances = entrances?.data?.data
      .filter((entrance: IEntrance) => {
        return (
          entrance.type === "outside" &&
          entrance.building_id === start?.building_id &&
          entrance.floor_id === start.floor_id
        );
      })
      .map((entrance: IEntrance) =>
        getCenter(entrance?.coordinates?.coordinates)
      );
  } else {
    const exits = entrances?.data?.data?.filter((entrance: IEntrance) => {
      return (
        entrance.type === "outside" &&
        entrance.building_id === start?.building_id
      );
    });

    const closestFloor =
      exits?.length > 0 &&
      exits
        ?.map((exit: IPoint) => getFloor(exit).level)
        ?.reduce((a: number, b: number) => {
          return Math.abs(b - getFloor(destination)?.level) <
            Math.abs(a - getFloor(destination)?.level)
            ? b
            : a;
        });

    startEntrances =
      exits?.length > 0 &&
      exits
        .filter((entrance: IPoint) => {
          return getFloor(entrance).level === closestFloor;
        })
        .map((entrance: IEntrance) =>
          getCenter(entrance?.coordinates?.coordinates)
        );
  }

  let destinationPosition: any = destination;
  let startPosition: any = start || null;

  // GET COORDINATES OF START AND DESTINATION POINTS
  if (start?.lat || start?.coordinates || start?.coordinates_x) {
    destinationPosition =
      destinationEntrances?.length > 0
        ? findClosest(
            map,
            destinationEntrances,
            getLatLng(start?.coordinates || start)
          )
        : getLatLng(destination);
    startPosition =
      startEntrances?.length > 0
        ? findClosest(
            map,
            startEntrances,
            getLatLng(destination?.coordinates || destination)
          )
        : getLatLng(start);
  }

  const addPolyLines = (coords: Array<LatLng>) => {
    const polyLine = L.polyline(coords, lineOptions);
    prevPolyline = polyLine;
    map && polyLine.addTo(map);
  };

  map && removePaths(map);

  const lineOptions = { color: routeColor, opacity: 1, weight: 6 };
  const LF: any = L;

  const route = LF.Routing.control({
    formatter: new L.Routing.Formatter({
      language: "pl",
    }),
    router: LF.Routing.osrmv1({
      serviceUrl: "https://osrm.etd24.pl/route/v1",
      profile: "drive",
      language: "pl",
    }),
    lineOptions: {
      styles: [lineOptions],
      addWaypoints: false,
    },
    pointMarkerStyle: {
      fillColor: "black",
      color: "#0091ff",
      radius: 5,
    },
    createMarker: function(i: number, waypoint: any, n: number) {
      if (i === 0) {
        return L.marker(waypoint.latLng, {
          draggable: true,
          icon: L.icon({
            iconUrl: "images/start.svg",
            iconSize: [30, 30],
            iconAnchor: [15, 30],
          }),
        }).on("dragend", (e: any) => {
          const point = e.target.getLatLng();
          history.push(`/paths/${destination.url}/${point.lat}-${point.lng}`);
        });
      }
    },
    fitSelectedRoutes: false,
    waypoints: [null, null],
    showAlternatives: false,
  });

  prevControl = route;

  route.on("routeselected", (e: any) => {
    addPolyLines([
      e.route.coordinates[e.route.coordinates.length - 1],
      getLatLng(destinationPosition),
    ]);
  });

  if (map && destination?.building_id !== start?.building_id) {
    map.off("click");
    removePaths(map);
    if (startPosition && startPosition.lat) {
      route.setWaypoints([
        getLatLng(startPosition),
        getLatLng(destinationPosition),
      ]);
    } else {
      map.on("click", function(e: any) {
        const url = history.location.pathname.split("/");
        if (url.includes("paths")) {
          history.push(
            `/paths/${destination.url}/${e.latlng.lat}-${e.latlng.lng}`
          );
          map.off("click");
        }
      });
    }
    const control = route.onAdd(map);
    if (document?.getElementById("paths__tips") && control) {
      document.getElementById("paths__tips")?.appendChild(control);
    }
  }
};
