import React, { FunctionComponent, useMemo, useContext } from "react";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { IPoint } from "../../../interfaces";
import { getCenter } from "../../../services/positions";
import { StairsContext, IStairsContext } from "../../../contexts/StairsContext";

interface IMarkersList {
  data?: Array<IPoint> | null;
  selectedColor?: String;
}

const EntrancesMarkers: FunctionComponent<IMarkersList> = ({
  data,
  selectedColor,
}) => {
  const { stairsPoints }: IStairsContext = useContext(StairsContext);

  const getCurrentIcon = (point: IPoint) => {
    const selected =
      stairsPoints?.start?.includes(point.id) ||
      stairsPoints?.destination?.includes(point.id);

    const stairs = `<span style="background: ${
      selected ? selectedColor : "#ffffff"
    }" class="marker__icon" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497 0H376.5c-8.284 0-15 6.716-15 15v105.5H256c-8.284 0-15 6.716-15 15V241H135.5c-8.284 0-15 6.716-15 15v105.5H15c-8.284 0-15 6.716-15 15V497c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15z"/></svg></span>`;
    const elevator = `<span style="background: ${
      selected ? selectedColor : "#ffffff"
    }" ><svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512" ><path d="M60.343 0v471.418h34V34h329.434v437.418h34V0z"/><path d="M104.194 43.906h149.255V444.51H104.194zM264.672 43.906h149.254V444.51H264.672zM13.641 281.419h36.811v-91.362H13.641v91.362zm18.403-79.784l11.34 19.123h-22.68l11.34-19.123zm11.34 49.083l-11.34 19.123-11.34-19.123h22.68z"/></svg></span>`;

    switch (point?.type) {
      case "outside":
        return L.icon({
          iconUrl: "/images/circle.svg",
          iconSize: [12, 12],
          iconAnchor: [0, 6],
          popupAnchor: [0, -12],
        });
      case "stairs":
        return L.divIcon({
          iconSize: [40, 40],
          iconAnchor: [0, 20],
          popupAnchor: [2, -40],
          html: stairs,
        });
      case "elevator":
        return L.divIcon({
          iconSize: [40, 40],
          iconAnchor: [0, 20],
          popupAnchor: [2, -40],
          html: elevator,
        });

      default:
        break;
    }
  };

  const getPopupInfo = (point: IPoint) => {
    switch (point?.type) {
      case "outside":
        return "Drzwi do budynku";
      case "stairs":
        return "Schody";
      case "elevator":
        return "Winda";
      default:
        return;
    }
  };

  const currentData = useMemo(() => {
    if (data && data?.length > 0) {
      return data?.filter(
        (point: IPoint) =>
          point?.type !== "inside" && point?.type !== "inside_hallway"
      );
    }
  }, [data]);

  return (
    <>
      {currentData?.map((point: IPoint, row: number) => {
        return (
          getCenter(point?.coordinates?.coordinates?.flat(2)) && (
            <Marker
              key={row}
              draggable={false}
              position={{
                lat: getCenter(point?.coordinates?.coordinates?.flat(2)).lng,
                lng: getCenter(point?.coordinates?.coordinates?.flat(2)).lat,
              }}
              icon={point && getCurrentIcon(point)}
              onMouseOver={(e: any) => e.target.openPopup()}
              onMouseOut={(e: any) => e.target.closePopup()}
            >
              <Popup>{getPopupInfo(point)}</Popup>
            </Marker>
          )
        );
      })}
    </>
  );
};

export default EntrancesMarkers;
