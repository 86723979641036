import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, DefaultApiResult } from "../../redux/reducers";
import { Dispatch } from "redux";
import { fetchEvents } from "../../redux/events/actions";

import Box from "../../components/Box";
import DataList from "../../components/FormElements/DataList";

const Events: FunctionComponent = () => {
  const dispatch: Dispatch = useDispatch();

  const events: DefaultApiResult = useSelector<RootState, DefaultApiResult>(
    (state) => state.Events
  );

  useEffect(() => {
    !events?.data?.data && !events?.loading && dispatch(fetchEvents());
  }, [dispatch, events]);

  return (
    <section className="container element event element--full ">
      <div className="scrollable">
        <Box title="Wydarzenia" link="/events">
          <DataList
            data={events?.data?.data}
            loading={events?.loading}
            container={"event"}
            link={"event"}
            msg={"Brak wydarzeń w obrębie kampusu"}
          />
        </Box>
      </div>
    </section>
  );
};

export default Events;
