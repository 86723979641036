import { call, put, all, takeEvery } from "redux-saga/effects";
import API from "../services/api";
import { DefaultApiAction } from "./reducers";
import { toast } from "react-toastify";

export function createSaga(
  prefix: string,
  endpoint: string,
  type = "FETCH",
  method = "GET"
) {
  function* fetch(action: DefaultApiAction) {
    let data: DefaultApiAction = Object.assign({}, action);
    let newEndpoint = endpoint;
    let actionData = {};

    if (action.id) {
      if (prefix === "QUESTION_ANSWERS") {
        newEndpoint = `${endpoint}/${action.id}/answers`;
      } else {
        newEndpoint = `${endpoint}/${action.id}`;
      }
    }

    if (action.buildingId) {
      actionData = {
        buildingId: action.buildingId,
        floorId: action.floorId,
      };
    }

    try {
      const response = yield call(API.call, newEndpoint, method, action.data);
      const responseBody = yield call([response, "json"]);

      if (response.status >= 400) {
        toast.error(responseBody.message);
        yield put({
          type: `${type}_${prefix}_FAILURE`,
          data,
          errorCode: response.status,
          payload: responseBody,
        });
      } else {
        yield put({
          type: `${type}_${prefix}_SUCCESS`,
          payload: responseBody,
          ...actionData,
        });
      }
    } catch (e) {
      toast.error(e.message);
      yield put({
        type: `${type}_${prefix}_FAILURE`,
        error: e.message,
      });
    }
  }

  return function* rootSaga() {
    yield all([takeEvery(`${type}_${prefix}`, fetch)]);
  };
}

export default function* rootSaga(getState: any) {
  yield all([
    createSaga("BUILDINGS", "buildings", "GET")(),
    createSaga("BUILDINGS", "buildings")(),

    createSaga("CAMPUSES", "campuses")(),
    createSaga("CAMPUSES", "campuses", "GET")(),

    createSaga("MAPS", "maps")(),
    createSaga("MAPS", "maps", "GET")(),

    createSaga("OCCASIONAL_EVENTS", "occasional_events")(),
    createSaga("OCCASIONAL_EVENTS", "occasional_events", "GET")(),

    createSaga("FLOORS", "floors")(),
    createSaga("FLOORS", "floors", "GET")(),

    createSaga("POINTS", "points")(),
    createSaga("POINTS", "points", "GET")(),

    createSaga("QUESTIONS", "questions")(),
    createSaga("QUESTIONS", "questions", "GET")(),
    createSaga("QUESTION_ANSWERS", "questions")(),

    createSaga("ROOMS", "rooms")(),
    createSaga("ROOMS", "rooms", "GET")(),

    createSaga("ENTRANCES", "entrances")(),
    createSaga("ENTRANCES", "entrances", "GET")(),

    createSaga("ROUTES", "routes")(),
    createSaga("ROUTES", "routes", "GET")(),

    createSaga("SETTINGS", "settings")(),
  ]);
}
