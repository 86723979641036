import L, { LatLng } from "leaflet";
import { IPoint } from "../interfaces";

const geoutil = require("leaflet-geometryutil");

export const getBounds = (coords: any) => {
  if (coords?.length > 0) {
    const polygon: any = L.polygon(coords, {});
    if (polygon?.getBounds()) {
      return polygon.getBounds();
    }
  }
};

export const getCenter = (coords: any) => {
  if (coords?.length > 0) {
    const polygon: any = L.polygon(coords, {});
    if (polygon?.getBounds()) {
      return polygon.getBounds().getCenter();
    }
  }
};

export const checkIfContainsPoint = (coords: any, point: any) => {
  if (coords?.length > 0 && point?.length > 0) {
    if (coords[0]?.length > 3) {
      const turf = require("@turf/turf");
      const tPolygon = turf.polygon(coords);
      const tPoint = turf.point(point);
      return turf.inside(tPoint, tPolygon);
    } else {
      const polygon: any = L.polygon(coords, {});

      if (polygon?.getBounds()) {
        return polygon.getBounds().contains(point);
      }
      return false;
    }
  }
};

export const getDistance = (coords: any, userLocation: any, msg: string) => {
  const isResolved = userLocation?.status === "resolved";

  if (getCenter(coords) && isResolved) {
    const from: any = getCenter(coords);
    const to: any = {
      lat: userLocation.position[1],
      lng: userLocation.position[0],
    };
    let currentDistance = from.distanceTo(to).toFixed(0);
    let unit = currentDistance > 1000 ? " km" : " m";

    const distance = `${
      currentDistance > 1000
        ? (currentDistance / 1000).toFixed(1)
        : currentDistance
    } ${unit}`;

    const inCampus = checkIfContainsPoint(coords, to);

    return inCampus ? msg : distance;
  } else {
    return "Brak danych";
  }
};

export const findClosest = (
  map: any,
  coords: Array<Array<number>>,
  point: any
) => {
  let closest = null;
  if (coords?.length > 0) {
    closest = geoutil.closest(
      map,
      coords.map((e: any) => {
        return e?.lat ? [e.lat, e.lng] : e;
      }),
      [point?.lat, point?.lng],
      true
    );
  }
  return closest;
};

export const findClosestSnap = (
  map: any,
  coords: Array<Array<number>>,
  point: LatLng
) => {
  const closest = geoutil.closest(
    map,
    coords.map((e: any) => {
      return e?.lat ? [e.lat, e.lng] : e;
    }),
    [point.lat, point.lng],
    "infinity",
    false
  );
  return closest;
};

export const findClosestLayer = (
  map: any,
  layers: Array<Array<number>>,
  point: LatLng
) => {
  let closest = null;
  if (layers?.length > 0) {
    closest = geoutil.closestLayer(map, layers, [point?.lat, point?.lng]);
  }
  return closest;
};

export const convertXY = (x: number, y: number) => {
  if (x && y) {
    return {
      coordinates: [[[[x, y]]]],
    };
  }
};

export const findClosestMarker = (
  map: any,
  points: Array<IPoint>,
  currentPoint: LatLng
) => {
  const closest = findClosestLayer(
    map,
    points.map((point: any) => point?.closest?.layer),
    currentPoint
  );
  const closestMarker = points.find(
    (point: any) =>
      point?.closest?.layer[0][0] === closest?.layer[0][0] &&
      point?.closest?.layer[0][1] === closest?.layer[0][1]
  );
  if (
    closestMarker &&
    closestMarker?.id &&
    closestMarker?.type !== "room" &&
    closestMarker?.type !== "point"
  ) {
    return closestMarker;
  }
};
